import React, { useEffect, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import Image from "next/legacy/image";
import LegoButton from '../assets/images/lego_button.png';

const Container = styled.div`
  position: fixed;
  left: 0;
  top: 54px;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: ${ ({theme}) => theme.colors.brightYellow };
  padding: 0 12px;
  z-index: 3;
`;

const Text = styled.h4`
  font-family: ${ ({theme}) => theme.fonts.cera };
  color: ${ ({theme}) => theme.colors.allWhite };
  font-size: 22px;
  line-height: 1.2;
  font-weight: 700;
  margin: 0;
  text-align: center;
`;

const ButtonsContainer = styled.div`
  display: flex;
  width: 320px;
  margin-top: 32px;
`;

export function Loading() {
  const [buttonsNumber, setButtonsNumber] = useState(1);

  useEffect(() => {
    let isUnmounted = false;
    const timeout = setTimeout(() => {
      if (!isUnmounted) setButtonsNumber(buttonsNumber === 10 ? 1 : buttonsNumber + 1)
    }, 100);
    return () => {
      isUnmounted = true;
      clearTimeout(timeout);
    }
  }, [buttonsNumber])

  const Buttons = useMemo(() => [...Array(buttonsNumber)].map((x, i) => <Image key={i} src={LegoButton} width={32} height={32} quality={100} alt="Lego button" />), [buttonsNumber])

  return (
    <Container>
        <Text>
          <FormattedMessage
            defaultMessage="Loading..."
            id="loading"
          />
        </Text>
      <ButtonsContainer>
        {Buttons}
      </ButtonsContainer>
    </Container>
  )
}