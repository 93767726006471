import { useCallback, useEffect } from 'react';
import { useRouter } from 'next/router';
import { pageview } from '../lib/ga';

export default function useSendRouteChangeToGa(): void {
  const router = useRouter();
  const handleRouteChange = useCallback((url: string) => {
    pageview(url);
  }, []);

  useEffect(() => {
    //When the component is mounted, subscribe to router changes
    //and log those page views
    router.events.on('routeChangeComplete', handleRouteChange);

    // If the component is unmounted, unsubscribe
    // from the event with the `off` method
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    }
  }, [router.events, handleRouteChange]);
}
