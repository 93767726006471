import { createAction } from '@reduxjs/toolkit';
import { FrameImageType } from '../types';

function onSelectRatio(ratio: FrameImageType['ratio']) {
  return {
    payload: ratio,
  };
}

export const selectRatio = createAction('card/select-ratio', onSelectRatio);
