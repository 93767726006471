import { createAction } from '@reduxjs/toolkit';
import { Locale } from '../types';

function onSetLocale(locale: Locale) {
  return {
    payload: locale,
  };
}

export const setLocale = createAction('i18n/set-locale', onSetLocale);
