import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FrameStyle } from '../types';
import { selectStyle } from '../actions/select-style';
import { resetCard } from '../actions/reset-card';

export type StyleState = {
  style: FrameStyle | '',
};

const initialState: StyleState = {
  style: '',
};

function onSelectStyle(state: StyleState, { payload }: PayloadAction<FrameStyle>): void {
  state.style = payload;
}

function onResetStyle(state: StyleState): void {
  state.style = initialState.style;
}

export const styleSlice = createSlice<
  StyleState,
  any,
  'style'
>({
  name: 'style',
  initialState,
  reducers: {},
  extraReducers: {
    [selectStyle as unknown as string]: onSelectStyle,
    [resetCard as unknown as string]: onResetStyle,
  },
});
