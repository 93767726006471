import { FrameType } from '../store/types';

export type FramesType = Array<FrameType>;

export const CardFrames: FramesType = [
  {
    categories: ['animals'],
    images: [
      {
        ratio: 'landscape',
        locale: 'ca',
        url: '/frames/christmas/1_ca_fantasy_landscape_animals.png',
        style: 'fantasy',
      },
      {
        ratio: 'portrait',
        locale: 'ca',
        url: '/frames/christmas/1_ca_fantasy_portrait_animals.png',
        style: 'fantasy',
      },
      {
        ratio: 'square',
        locale: 'ca',
        url: '/frames/christmas/1_ca_fantasy_square_animals.png',
        style: 'fantasy',
      },
      {
        ratio: 'landscape',
        locale: 'en',
        url: '/frames/christmas/1_en_fantasy_landscape_animals.png',
        style: 'fantasy',
      },
      {
        ratio: 'portrait',
        locale: 'en',
        url: '/frames/christmas/1_en_fantasy_portrait_animals.png',
        style: 'fantasy',
      },
      {
        ratio: 'square',
        locale: 'en',
        url: '/frames/christmas/1_en_fantasy_square_animals.png',
        style: 'fantasy',
      },
      {
        ratio: 'landscape',
        locale: 'es',
        url: '/frames/christmas/1_es_fantasy_landscape_animals.png',
        style: 'fantasy',
      },
      {
        ratio: 'portrait',
        locale: 'es',
        url: '/frames/christmas/1_es_fantasy_portrait_animals.png',
        style: 'fantasy',
      },
      {
        ratio: 'square',
        locale: 'es',
        url: '/frames/christmas/1_es_fantasy_square_animals.png',
        style: 'fantasy',
      },
      {
        ratio: 'landscape',
        locale: 'fr',
        url: '/frames/christmas/1_fr_fantasy_landscape_animals.png',
        style: 'fantasy',
      },
      {
        ratio: 'portrait',
        locale: 'fr',
        url: '/frames/christmas/1_fr_fantasy_portrait_animals.png',
        style: 'fantasy',
      },
      {
        ratio: 'square',
        locale: 'fr',
        url: '/frames/christmas/1_fr_fantasy_square_animals.png',
        style: 'fantasy',
      },
      {
        ratio: 'landscape',
        locale: 'it',
        url: '/frames/christmas/1_it_fantasy_landscape_animals.png',
        style: 'fantasy',
      },
      {
        ratio: 'portrait',
        locale: 'it',
        url: '/frames/christmas/1_it_fantasy_portrait_animals.png',
        style: 'fantasy',
      },
      {
        ratio: 'square',
        locale: 'it',
        url: '/frames/christmas/1_it_fantasy_square_animals.png',
        style: 'fantasy',
      },
      {
        ratio: 'landscape',
        locale: 'pt',
        url: '/frames/christmas/1_pt_fantasy_landscape_animals.png',
        style: 'fantasy',
      },
      {
        ratio: 'portrait',
        locale: 'pt',
        url: '/frames/christmas/1_pt_fantasy_portrait_animals.png',
        style: 'fantasy',
      },
      {
        ratio: 'square',
        locale: 'pt',
        url: '/frames/christmas/1_pt_fantasy_square_animals.png',
        style: 'fantasy',
      },
    ],
    style: 'fantasy',
    id: 1,
  },
  {
    categories: ['animals'],
    images: [
      {
        ratio: 'landscape',
        locale: 'ca',
        url: '/frames/christmas/2_ca_lego_landscape_animals.png',
        style: 'lego',
      },
      {
        ratio: 'portrait',
        locale: 'ca',
        url: '/frames/christmas/2_ca_lego_portrait_animals.png',
        style: 'lego',
      },
      {
        ratio: 'square',
        locale: 'ca',
        url: '/frames/christmas/2_ca_lego_square_animals.png',
        style: 'lego',
      },
      {
        ratio: 'landscape',
        locale: 'en',
        url: '/frames/christmas/2_en_lego_landscape_animals.png',
        style: 'lego',
      },
      {
        ratio: 'portrait',
        locale: 'en',
        url: '/frames/christmas/2_en_lego_portrait_animals.png',
        style: 'lego',
      },
      {
        ratio: 'square',
        locale: 'en',
        url: '/frames/christmas/2_en_lego_square_animals.png',
        style: 'lego',
      },
      {
        ratio: 'landscape',
        locale: 'es',
        url: '/frames/christmas/2_es_lego_landscape_animals.png',
        style: 'lego',
      },
      {
        ratio: 'portrait',
        locale: 'es',
        url: '/frames/christmas/2_es_lego_portrait_animals.png',
        style: 'lego',
      },
      {
        ratio: 'square',
        locale: 'es',
        url: '/frames/christmas/2_es_lego_square_animals.png',
        style: 'lego',
      },
      {
        ratio: 'landscape',
        locale: 'fr',
        url: '/frames/christmas/2_fr_lego_landscape_animals.png',
        style: 'lego',
      },
      {
        ratio: 'portrait',
        locale: 'fr',
        url: '/frames/christmas/2_fr_lego_portrait_animals.png',
        style: 'lego',
      },
      {
        ratio: 'square',
        locale: 'fr',
        url: '/frames/christmas/2_fr_lego_square_animals.png',
        style: 'lego',
      },
      {
        ratio: 'landscape',
        locale: 'it',
        url: '/frames/christmas/2_it_lego_landscape_animals.png',
        style: 'lego',
      },
      {
        ratio: 'portrait',
        locale: 'it',
        url: '/frames/christmas/2_it_lego_portrait_animals.png',
        style: 'lego',
      },
      {
        ratio: 'square',
        locale: 'it',
        url: '/frames/christmas/2_it_lego_square_animals.png',
        style: 'lego',
      },
      {
        ratio: 'landscape',
        locale: 'pt',
        url: '/frames/christmas/2_pt_lego_landscape_animals.png',
        style: 'lego',
      },
      {
        ratio: 'portrait',
        locale: 'pt',
        url: '/frames/christmas/2_pt_lego_portrait_animals.png',
        style: 'lego',
      },
      {
        ratio: 'square',
        locale: 'pt',
        url: '/frames/christmas/2_pt_lego_square_animals.png',
        style: 'lego',
      },
    ],
    style: 'lego',
    id: 2,
  },
  {
    categories: ['animals'],
    images: [
      {
        ratio: 'landscape',
        locale: 'ca',
        url: '/frames/christmas/3_ca_general_landscape_animals.png',
        style: 'general',
      },
      {
        ratio: 'portrait',
        locale: 'ca',
        url: '/frames/christmas/3_ca_general_portrait_animals.png',
        style: 'general',
      },
      {
        ratio: 'square',
        locale: 'ca',
        url: '/frames/christmas/3_ca_general_square_animals.png',
        style: 'general',
      },
      {
        ratio: 'landscape',
        locale: 'en',
        url: '/frames/christmas/3_en_general_landscape_animals.png',
        style: 'general',
      },
      {
        ratio: 'portrait',
        locale: 'en',
        url: '/frames/christmas/3_en_general_portrait_animals.png',
        style: 'general',
      },
      {
        ratio: 'square',
        locale: 'en',
        url: '/frames/christmas/3_en_general_square_animals.png',
        style: 'general',
      },
      {
        ratio: 'landscape',
        locale: 'es',
        url: '/frames/christmas/3_es_general_landscape_animals.png',
        style: 'general',
      },
      {
        ratio: 'portrait',
        locale: 'es',
        url: '/frames/christmas/3_es_general_portrait_animals.png',
        style: 'general',
      },
      {
        ratio: 'square',
        locale: 'es',
        url: '/frames/christmas/3_es_general_square_animals.png',
        style: 'general',
      },
      {
        ratio: 'landscape',
        locale: 'fr',
        url: '/frames/christmas/3_fr_general_landscape_animals.png',
        style: 'general',
      },
      {
        ratio: 'portrait',
        locale: 'fr',
        url: '/frames/christmas/3_fr_general_portrait_animals.png',
        style: 'general',
      },
      {
        ratio: 'square',
        locale: 'fr',
        url: '/frames/christmas/3_fr_general_square_animals.png',
        style: 'general',
      },
      {
        ratio: 'landscape',
        locale: 'it',
        url: '/frames/christmas/3_it_general_landscape_animals.png',
        style: 'general',
      },
      {
        ratio: 'portrait',
        locale: 'it',
        url: '/frames/christmas/3_it_general_portrait_animals.png',
        style: 'general',
      },
      {
        ratio: 'square',
        locale: 'it',
        url: '/frames/christmas/3_it_general_square_animals.png',
        style: 'general',
      },
      {
        ratio: 'landscape',
        locale: 'pt',
        url: '/frames/christmas/3_pt_general_landscape_animals.png',
        style: 'general',
      },
      {
        ratio: 'portrait',
        locale: 'pt',
        url: '/frames/christmas/3_pt_general_portrait_animals.png',
        style: 'general',
      },
      {
        ratio: 'square',
        locale: 'pt',
        url: '/frames/christmas/3_pt_general_square_animals.png',
        style: 'general',
      },
    ],
    style: 'general',
    id: 3,
  },
  {
    categories: ['music'],
    images: [
      {
        ratio: 'landscape',
        locale: 'ca',
        url: '/frames/christmas/4_ca_fantasy_landscape_music.png',
        style: 'fantasy',
      },
      {
        ratio: 'portrait',
        locale: 'ca',
        url: '/frames/christmas/4_ca_fantasy_portrait_music.png',
        style: 'fantasy',
      },
      {
        ratio: 'square',
        locale: 'ca',
        url: '/frames/christmas/4_ca_fantasy_square_music.png',
        style: 'fantasy',
      },
      {
        ratio: 'landscape',
        locale: 'en',
        url: '/frames/christmas/4_en_fantasy_landscape_music.png',
        style: 'fantasy',
      },
      {
        ratio: 'portrait',
        locale: 'en',
        url: '/frames/christmas/4_en_fantasy_portrait_music.png',
        style: 'fantasy',
      },
      {
        ratio: 'square',
        locale: 'en',
        url: '/frames/christmas/4_en_fantasy_square_music.png',
        style: 'fantasy',
      },
      {
        ratio: 'landscape',
        locale: 'es',
        url: '/frames/christmas/4_es_fantasy_landscape_music.png',
        style: 'fantasy',
      },
      {
        ratio: 'portrait',
        locale: 'es',
        url: '/frames/christmas/4_es_fantasy_portrait_music.png',
        style: 'fantasy',
      },
      {
        ratio: 'square',
        locale: 'es',
        url: '/frames/christmas/4_es_fantasy_square_music.png',
        style: 'fantasy',
      },
      {
        ratio: 'landscape',
        locale: 'fr',
        url: '/frames/christmas/4_fr_fantasy_landscape_music.png',
        style: 'fantasy',
      },
      {
        ratio: 'portrait',
        locale: 'fr',
        url: '/frames/christmas/4_fr_fantasy_portrait_music.png',
        style: 'fantasy',
      },
      {
        ratio: 'square',
        locale: 'fr',
        url: '/frames/christmas/4_fr_fantasy_square_music.png',
        style: 'fantasy',
      },
      {
        ratio: 'landscape',
        locale: 'it',
        url: '/frames/christmas/4_it_fantasy_landscape_music.png',
        style: 'fantasy',
      },
      {
        ratio: 'portrait',
        locale: 'it',
        url: '/frames/christmas/4_it_fantasy_portrait_music.png',
        style: 'fantasy',
      },
      {
        ratio: 'square',
        locale: 'it',
        url: '/frames/christmas/4_it_fantasy_square_music.png',
        style: 'fantasy',
      },
      {
        ratio: 'landscape',
        locale: 'pt',
        url: '/frames/christmas/4_pt_fantasy_landscape_music.png',
        style: 'fantasy',
      },
      {
        ratio: 'portrait',
        locale: 'pt',
        url: '/frames/christmas/4_pt_fantasy_portrait_music.png',
        style: 'fantasy',
      },
      {
        ratio: 'square',
        locale: 'pt',
        url: '/frames/christmas/4_pt_fantasy_square_music.png',
        style: 'fantasy',
      },
    ],
    style: 'fantasy',
    id: 4,
  },
  {
    categories: ['music'],
    images: [
      {
        ratio: 'landscape',
        locale: 'ca',
        url: '/frames/christmas/5_ca_lego_landscape_music.png',
        style: 'lego',
      },
      {
        ratio: 'portrait',
        locale: 'ca',
        url: '/frames/christmas/5_ca_lego_portrait_music.png',
        style: 'lego',
      },
      {
        ratio: 'square',
        locale: 'ca',
        url: '/frames/christmas/5_ca_lego_square_music.png',
        style: 'lego',
      },
      {
        ratio: 'landscape',
        locale: 'en',
        url: '/frames/christmas/5_en_lego_landscape_music.png',
        style: 'lego',
      },
      {
        ratio: 'portrait',
        locale: 'en',
        url: '/frames/christmas/5_en_lego_portrait_music.png',
        style: 'lego',
      },
      {
        ratio: 'square',
        locale: 'en',
        url: '/frames/christmas/5_en_lego_square_music.png',
        style: 'lego',
      },
      {
        ratio: 'landscape',
        locale: 'es',
        url: '/frames/christmas/5_es_lego_landscape_music.png',
        style: 'lego',
      },
      {
        ratio: 'portrait',
        locale: 'es',
        url: '/frames/christmas/5_es_lego_portrait_music.png',
        style: 'lego',
      },
      {
        ratio: 'square',
        locale: 'es',
        url: '/frames/christmas/5_es_lego_square_music.png',
        style: 'lego',
      },
      {
        ratio: 'landscape',
        locale: 'fr',
        url: '/frames/christmas/5_fr_lego_landscape_music.png',
        style: 'lego',
      },
      {
        ratio: 'portrait',
        locale: 'fr',
        url: '/frames/christmas/5_fr_lego_portrait_music.png',
        style: 'lego',
      },
      {
        ratio: 'square',
        locale: 'fr',
        url: '/frames/christmas/5_fr_lego_square_music.png',
        style: 'lego',
      },
      {
        ratio: 'landscape',
        locale: 'it',
        url: '/frames/christmas/5_it_lego_landscape_music.png',
        style: 'lego',
      },
      {
        ratio: 'portrait',
        locale: 'it',
        url: '/frames/christmas/5_it_lego_portrait_music.png',
        style: 'lego',
      },
      {
        ratio: 'square',
        locale: 'it',
        url: '/frames/christmas/5_it_lego_square_music.png',
        style: 'lego',
      },
      {
        ratio: 'landscape',
        locale: 'pt',
        url: '/frames/christmas/5_pt_lego_landscape_music.png',
        style: 'lego',
      },
      {
        ratio: 'portrait',
        locale: 'pt',
        url: '/frames/christmas/5_pt_lego_portrait_music.png',
        style: 'lego',
      },
      {
        ratio: 'square',
        locale: 'pt',
        url: '/frames/christmas/5_pt_lego_square_music.png',
        style: 'lego',
      },
    ],
    style: 'lego',
    id: 5,
  },
  {
    categories: ['music'],
    images: [
      {
        ratio: 'landscape',
        locale: 'ca',
        url: '/frames/christmas/6_ca_general_landscape_music.png',
        style: 'general',
      },
      {
        ratio: 'portrait',
        locale: 'ca',
        url: '/frames/christmas/6_ca_general_portrait_music.png',
        style: 'general',
      },
      {
        ratio: 'square',
        locale: 'ca',
        url: '/frames/christmas/6_ca_general_square_music.png',
        style: 'general',
      },
      {
        ratio: 'landscape',
        locale: 'en',
        url: '/frames/christmas/6_en_general_landscape_music.png',
        style: 'general',
      },
      {
        ratio: 'portrait',
        locale: 'en',
        url: '/frames/christmas/6_en_general_portrait_music.png',
        style: 'general',
      },
      {
        ratio: 'square',
        locale: 'en',
        url: '/frames/christmas/6_en_general_square_music.png',
        style: 'general',
      },
      {
        ratio: 'landscape',
        locale: 'es',
        url: '/frames/christmas/6_es_general_landscape_music.png',
        style: 'general',
      },
      {
        ratio: 'portrait',
        locale: 'es',
        url: '/frames/christmas/6_es_general_portrait_music.png',
        style: 'general',
      },
      {
        ratio: 'square',
        locale: 'es',
        url: '/frames/christmas/6_es_general_square_music.png',
        style: 'general',
      },
      {
        ratio: 'landscape',
        locale: 'fr',
        url: '/frames/christmas/6_fr_general_landscape_music.png',
        style: 'general',
      },
      {
        ratio: 'portrait',
        locale: 'fr',
        url: '/frames/christmas/6_fr_general_portrait_music.png',
        style: 'general',
      },
      {
        ratio: 'square',
        locale: 'fr',
        url: '/frames/christmas/6_fr_general_square_music.png',
        style: 'general',
      },
      {
        ratio: 'landscape',
        locale: 'it',
        url: '/frames/christmas/6_it_general_landscape_music.png',
        style: 'general',
      },
      {
        ratio: 'portrait',
        locale: 'it',
        url: '/frames/christmas/6_it_general_portrait_music.png',
        style: 'general',
      },
      {
        ratio: 'square',
        locale: 'it',
        url: '/frames/christmas/6_it_general_square_music.png',
        style: 'general',
      },
      {
        ratio: 'landscape',
        locale: 'pt',
        url: '/frames/christmas/6_pt_general_landscape_music.png',
        style: 'general',
      },
      {
        ratio: 'portrait',
        locale: 'pt',
        url: '/frames/christmas/6_pt_general_portrait_music.png',
        style: 'general',
      },
      {
        ratio: 'square',
        locale: 'pt',
        url: '/frames/christmas/6_pt_general_square_music.png',
        style: 'general',
      },
    ],
    style: 'general',
    id: 6,
  },
  {
    categories: ['sport'],
    images: [
      {
        ratio: 'landscape',
        locale: 'ca',
        url: '/frames/christmas/7_ca_fantasy_landscape_sport.png',
        style: 'fantasy',
      },
      {
        ratio: 'portrait',
        locale: 'ca',
        url: '/frames/christmas/7_ca_fantasy_portrait_sport.png',
        style: 'fantasy',
      },
      {
        ratio: 'square',
        locale: 'ca',
        url: '/frames/christmas/7_ca_fantasy_square_sport.png',
        style: 'fantasy',
      },
      {
        ratio: 'landscape',
        locale: 'en',
        url: '/frames/christmas/7_en_fantasy_landscape_sport.png',
        style: 'fantasy',
      },
      {
        ratio: 'portrait',
        locale: 'en',
        url: '/frames/christmas/7_en_fantasy_portrait_sport.png',
        style: 'fantasy',
      },
      {
        ratio: 'square',
        locale: 'en',
        url: '/frames/christmas/7_en_fantasy_square_sport.png',
        style: 'fantasy',
      },
      {
        ratio: 'landscape',
        locale: 'es',
        url: '/frames/christmas/7_es_fantasy_landscape_sport.png',
        style: 'fantasy',
      },
      {
        ratio: 'portrait',
        locale: 'es',
        url: '/frames/christmas/7_es_fantasy_portrait_sport.png',
        style: 'fantasy',
      },
      {
        ratio: 'square',
        locale: 'es',
        url: '/frames/christmas/7_es_fantasy_square_sport.png',
        style: 'fantasy',
      },
      {
        ratio: 'landscape',
        locale: 'fr',
        url: '/frames/christmas/7_fr_fantasy_landscape_sport.png',
        style: 'fantasy',
      },
      {
        ratio: 'portrait',
        locale: 'fr',
        url: '/frames/christmas/7_fr_fantasy_portrait_sport.png',
        style: 'fantasy',
      },
      {
        ratio: 'square',
        locale: 'fr',
        url: '/frames/christmas/7_fr_fantasy_square_sport.png',
        style: 'fantasy',
      },
      {
        ratio: 'landscape',
        locale: 'it',
        url: '/frames/christmas/7_it_fantasy_landscape_sport.png',
        style: 'fantasy',
      },
      {
        ratio: 'portrait',
        locale: 'it',
        url: '/frames/christmas/7_it_fantasy_portrait_sport.png',
        style: 'fantasy',
      },
      {
        ratio: 'square',
        locale: 'it',
        url: '/frames/christmas/7_it_fantasy_square_sport.png',
        style: 'fantasy',
      },
      {
        ratio: 'landscape',
        locale: 'pt',
        url: '/frames/christmas/7_pt_fantasy_landscape_sport.png',
        style: 'fantasy',
      },
      {
        ratio: 'portrait',
        locale: 'pt',
        url: '/frames/christmas/7_pt_fantasy_portrait_sport.png',
        style: 'fantasy',
      },
      {
        ratio: 'square',
        locale: 'pt',
        url: '/frames/christmas/7_pt_fantasy_square_sport.png',
        style: 'fantasy',
      },
    ],
    style: 'fantasy',
    id: 7,
  },
  {
    categories: ['sport'],
    images: [
      {
        ratio: 'landscape',
        locale: 'ca',
        url: '/frames/christmas/8_ca_lego_landscape_sport.png',
        style: 'lego',
      },
      {
        ratio: 'portrait',
        locale: 'ca',
        url: '/frames/christmas/8_ca_lego_portrait_sport.png',
        style: 'lego',
      },
      {
        ratio: 'square',
        locale: 'ca',
        url: '/frames/christmas/8_ca_lego_square_sport.png',
        style: 'lego',
      },
      {
        ratio: 'landscape',
        locale: 'en',
        url: '/frames/christmas/8_en_lego_landscape_sport.png',
        style: 'lego',
      },
      {
        ratio: 'portrait',
        locale: 'en',
        url: '/frames/christmas/8_en_lego_portrait_sport.png',
        style: 'lego',
      },
      {
        ratio: 'square',
        locale: 'en',
        url: '/frames/christmas/8_en_lego_square_sport.png',
        style: 'lego',
      },
      {
        ratio: 'landscape',
        locale: 'es',
        url: '/frames/christmas/8_es_lego_landscape_sport.png',
        style: 'lego',
      },
      {
        ratio: 'portrait',
        locale: 'es',
        url: '/frames/christmas/8_es_lego_portrait_sport.png',
        style: 'lego',
      },
      {
        ratio: 'square',
        locale: 'es',
        url: '/frames/christmas/8_es_lego_square_sport.png',
        style: 'lego',
      },
      {
        ratio: 'landscape',
        locale: 'fr',
        url: '/frames/christmas/8_fr_lego_landscape_sport.png',
        style: 'lego',
      },
      {
        ratio: 'portrait',
        locale: 'fr',
        url: '/frames/christmas/8_fr_lego_portrait_sport.png',
        style: 'lego',
      },
      {
        ratio: 'square',
        locale: 'fr',
        url: '/frames/christmas/8_fr_lego_square_sport.png',
        style: 'lego',
      },
      {
        ratio: 'landscape',
        locale: 'it',
        url: '/frames/christmas/8_it_lego_landscape_sport.png',
        style: 'lego',
      },
      {
        ratio: 'portrait',
        locale: 'it',
        url: '/frames/christmas/8_it_lego_portrait_sport.png',
        style: 'lego',
      },
      {
        ratio: 'square',
        locale: 'it',
        url: '/frames/christmas/8_it_lego_square_sport.png',
        style: 'lego',
      },
      {
        ratio: 'landscape',
        locale: 'pt',
        url: '/frames/christmas/8_pt_lego_landscape_sport.png',
        style: 'lego',
      },
      {
        ratio: 'portrait',
        locale: 'pt',
        url: '/frames/christmas/8_pt_lego_portrait_sport.png',
        style: 'lego',
      },
      {
        ratio: 'square',
        locale: 'pt',
        url: '/frames/christmas/8_pt_lego_square_sport.png',
        style: 'lego',
      },
    ],
    style: 'lego',
    id: 8,
  },
  {
    categories: ['sport'],
    images: [
      {
        ratio: 'landscape',
        locale: 'ca',
        url: '/frames/christmas/9_ca_general_landscape_sport.png',
        style: 'general',
      },
      {
        ratio: 'portrait',
        locale: 'ca',
        url: '/frames/christmas/9_ca_general_portrait_sport.png',
        style: 'general',
      },
      {
        ratio: 'square',
        locale: 'ca',
        url: '/frames/christmas/9_ca_general_square_sport.png',
        style: 'general',
      },
      {
        ratio: 'landscape',
        locale: 'en',
        url: '/frames/christmas/9_en_general_landscape_sport.png',
        style: 'general',
      },
      {
        ratio: 'portrait',
        locale: 'en',
        url: '/frames/christmas/9_en_general_portrait_sport.png',
        style: 'general',
      },
      {
        ratio: 'square',
        locale: 'en',
        url: '/frames/christmas/9_en_general_square_sport.png',
        style: 'general',
      },
      {
        ratio: 'landscape',
        locale: 'es',
        url: '/frames/christmas/9_es_general_landscape_sport.png',
        style: 'general',
      },
      {
        ratio: 'portrait',
        locale: 'es',
        url: '/frames/christmas/9_es_general_portrait_sport.png',
        style: 'general',
      },
      {
        ratio: 'square',
        locale: 'es',
        url: '/frames/christmas/9_es_general_square_sport.png',
        style: 'general',
      },
      {
        ratio: 'landscape',
        locale: 'fr',
        url: '/frames/christmas/9_fr_general_landscape_sport.png',
        style: 'general',
      },
      {
        ratio: 'portrait',
        locale: 'fr',
        url: '/frames/christmas/9_fr_general_portrait_sport.png',
        style: 'general',
      },
      {
        ratio: 'square',
        locale: 'fr',
        url: '/frames/christmas/9_fr_general_square_sport.png',
        style: 'general',
      },
      {
        ratio: 'landscape',
        locale: 'it',
        url: '/frames/christmas/9_it_general_landscape_sport.png',
        style: 'general',
      },
      {
        ratio: 'portrait',
        locale: 'it',
        url: '/frames/christmas/9_it_general_portrait_sport.png',
        style: 'general',
      },
      {
        ratio: 'square',
        locale: 'it',
        url: '/frames/christmas/9_it_general_square_sport.png',
        style: 'general',
      },
      {
        ratio: 'landscape',
        locale: 'pt',
        url: '/frames/christmas/9_pt_general_landscape_sport.png',
        style: 'general',
      },
      {
        ratio: 'portrait',
        locale: 'pt',
        url: '/frames/christmas/9_pt_general_portrait_sport.png',
        style: 'general',
      },
      {
        ratio: 'square',
        locale: 'pt',
        url: '/frames/christmas/9_pt_general_square_sport.png',
        style: 'general',
      },
    ],
    style: 'general',
    id: 9,
  },
  {
    categories: ['adventure'],
    images: [
      {
        ratio: 'landscape',
        locale: 'ca',
        url: '/frames/christmas/10_ca_fantasy_landscape_adventure.png',
        style: 'fantasy',
      },
      {
        ratio: 'portrait',
        locale: 'ca',
        url: '/frames/christmas/10_ca_fantasy_portrait_adventure.png',
        style: 'fantasy',
      },
      {
        ratio: 'square',
        locale: 'ca',
        url: '/frames/christmas/10_ca_fantasy_square_adventure.png',
        style: 'fantasy',
      },
      {
        ratio: 'landscape',
        locale: 'en',
        url: '/frames/christmas/10_en_fantasy_landscape_adventure.png',
        style: 'fantasy',
      },
      {
        ratio: 'portrait',
        locale: 'en',
        url: '/frames/christmas/10_en_fantasy_portrait_adventure.png',
        style: 'fantasy',
      },
      {
        ratio: 'square',
        locale: 'en',
        url: '/frames/christmas/10_en_fantasy_square_adventure.png',
        style: 'fantasy',
      },
      {
        ratio: 'landscape',
        locale: 'es',
        url: '/frames/christmas/10_es_fantasy_landscape_adventure.png',
        style: 'fantasy',
      },
      {
        ratio: 'portrait',
        locale: 'es',
        url: '/frames/christmas/10_es_fantasy_portrait_adventure.png',
        style: 'fantasy',
      },
      {
        ratio: 'square',
        locale: 'es',
        url: '/frames/christmas/10_es_fantasy_square_adventure.png',
        style: 'fantasy',
      },
      {
        ratio: 'landscape',
        locale: 'fr',
        url: '/frames/christmas/10_fr_fantasy_landscape_adventure.png',
        style: 'fantasy',
      },
      {
        ratio: 'portrait',
        locale: 'fr',
        url: '/frames/christmas/10_fr_fantasy_portrait_adventure.png',
        style: 'fantasy',
      },
      {
        ratio: 'square',
        locale: 'fr',
        url: '/frames/christmas/10_fr_fantasy_square_adventure.png',
        style: 'fantasy',
      },
      {
        ratio: 'landscape',
        locale: 'it',
        url: '/frames/christmas/10_it_fantasy_landscape_adventure.png',
        style: 'fantasy',
      },
      {
        ratio: 'portrait',
        locale: 'it',
        url: '/frames/christmas/10_it_fantasy_portrait_adventure.png',
        style: 'fantasy',
      },
      {
        ratio: 'square',
        locale: 'it',
        url: '/frames/christmas/10_it_fantasy_square_adventure.png',
        style: 'fantasy',
      },
      {
        ratio: 'landscape',
        locale: 'pt',
        url: '/frames/christmas/10_pt_fantasy_landscape_adventure.png',
        style: 'fantasy',
      },
      {
        ratio: 'portrait',
        locale: 'pt',
        url: '/frames/christmas/10_pt_fantasy_portrait_adventure.png',
        style: 'fantasy',
      },
      {
        ratio: 'square',
        locale: 'pt',
        url: '/frames/christmas/10_pt_fantasy_square_adventure.png',
        style: 'fantasy',
      },
    ],
    style: 'fantasy',
    id: 10,
  },
  {
    categories: ['adventure'],
    images: [
      {
        ratio: 'landscape',
        locale: 'ca',
        url: '/frames/christmas/11_ca_lego_landscape_adventure.png',
        style: 'lego',
      },
      {
        ratio: 'portrait',
        locale: 'ca',
        url: '/frames/christmas/11_ca_lego_portrait_adventure.png',
        style: 'lego',
      },
      {
        ratio: 'square',
        locale: 'ca',
        url: '/frames/christmas/11_ca_lego_square_adventure.png',
        style: 'lego',
      },
      {
        ratio: 'landscape',
        locale: 'en',
        url: '/frames/christmas/11_en_lego_landscape_adventure.png',
        style: 'lego',
      },
      {
        ratio: 'portrait',
        locale: 'en',
        url: '/frames/christmas/11_en_lego_portrait_adventure.png',
        style: 'lego',
      },
      {
        ratio: 'square',
        locale: 'en',
        url: '/frames/christmas/11_en_lego_square_adventure.png',
        style: 'lego',
      },
      {
        ratio: 'landscape',
        locale: 'es',
        url: '/frames/christmas/11_es_lego_landscape_adventure.png',
        style: 'lego',
      },
      {
        ratio: 'portrait',
        locale: 'es',
        url: '/frames/christmas/11_es_lego_portrait_adventure.png',
        style: 'lego',
      },
      {
        ratio: 'square',
        locale: 'es',
        url: '/frames/christmas/11_es_lego_square_adventure.png',
        style: 'lego',
      },
      {
        ratio: 'landscape',
        locale: 'fr',
        url: '/frames/christmas/11_fr_lego_landscape_adventure.png',
        style: 'lego',
      },
      {
        ratio: 'portrait',
        locale: 'fr',
        url: '/frames/christmas/11_fr_lego_portrait_adventure.png',
        style: 'lego',
      },
      {
        ratio: 'square',
        locale: 'fr',
        url: '/frames/christmas/11_fr_lego_square_adventure.png',
        style: 'lego',
      },
      {
        ratio: 'landscape',
        locale: 'it',
        url: '/frames/christmas/11_it_lego_landscape_adventure.png',
        style: 'lego',
      },
      {
        ratio: 'portrait',
        locale: 'it',
        url: '/frames/christmas/11_it_lego_portrait_adventure.png',
        style: 'lego',
      },
      {
        ratio: 'square',
        locale: 'it',
        url: '/frames/christmas/11_it_lego_square_adventure.png',
        style: 'lego',
      },
      {
        ratio: 'landscape',
        locale: 'pt',
        url: '/frames/christmas/11_pt_lego_landscape_adventure.png',
        style: 'lego',
      },
      {
        ratio: 'portrait',
        locale: 'pt',
        url: '/frames/christmas/11_pt_lego_portrait_adventure.png',
        style: 'lego',
      },
      {
        ratio: 'square',
        locale: 'pt',
        url: '/frames/christmas/11_pt_lego_square_adventure.png',
        style: 'lego',
      },
    ],
    style: 'lego',
    id: 11,
  },
  {
    categories: ['adventure'],
    images: [
      {
        ratio: 'landscape',
        locale: 'ca',
        url: '/frames/christmas/12_ca_general_landscape_adventure.png',
        style: 'general',
      },
      {
        ratio: 'portrait',
        locale: 'ca',
        url: '/frames/christmas/12_ca_general_portrait_adventure.png',
        style: 'general',
      },
      {
        ratio: 'square',
        locale: 'ca',
        url: '/frames/christmas/12_ca_general_square_adventure.png',
        style: 'general',
      },
      {
        ratio: 'landscape',
        locale: 'en',
        url: '/frames/christmas/12_en_general_landscape_adventure.png',
        style: 'general',
      },
      {
        ratio: 'portrait',
        locale: 'en',
        url: '/frames/christmas/12_en_general_portrait_adventure.png',
        style: 'general',
      },
      {
        ratio: 'square',
        locale: 'en',
        url: '/frames/christmas/12_en_general_square_adventure.png',
        style: 'general',
      },
      {
        ratio: 'landscape',
        locale: 'es',
        url: '/frames/christmas/12_es_general_landscape_adventure.png',
        style: 'general',
      },
      {
        ratio: 'portrait',
        locale: 'es',
        url: '/frames/christmas/12_es_general_portrait_adventure.png',
        style: 'general',
      },
      {
        ratio: 'square',
        locale: 'es',
        url: '/frames/christmas/12_es_general_square_adventure.png',
        style: 'general',
      },
      {
        ratio: 'landscape',
        locale: 'fr',
        url: '/frames/christmas/12_fr_general_landscape_adventure.png',
        style: 'general',
      },
      {
        ratio: 'portrait',
        locale: 'fr',
        url: '/frames/christmas/12_fr_general_portrait_adventure.png',
        style: 'general',
      },
      {
        ratio: 'square',
        locale: 'fr',
        url: '/frames/christmas/12_fr_general_square_adventure.png',
        style: 'general',
      },
      {
        ratio: 'landscape',
        locale: 'it',
        url: '/frames/christmas/12_it_general_landscape_adventure.png',
        style: 'general',
      },
      {
        ratio: 'portrait',
        locale: 'it',
        url: '/frames/christmas/12_it_general_portrait_adventure.png',
        style: 'general',
      },
      {
        ratio: 'square',
        locale: 'it',
        url: '/frames/christmas/12_it_general_square_adventure.png',
        style: 'general',
      },
      {
        ratio: 'landscape',
        locale: 'pt',
        url: '/frames/christmas/12_pt_general_landscape_adventure.png',
        style: 'general',
      },
      {
        ratio: 'portrait',
        locale: 'pt',
        url: '/frames/christmas/12_pt_general_portrait_adventure.png',
        style: 'general',
      },
      {
        ratio: 'square',
        locale: 'pt',
        url: '/frames/christmas/12_pt_general_square_adventure.png',
        style: 'general',
      },
    ],
    style: 'general',
    id: 12,
  },
  {
    categories: ['animals', 'adventure'],
    images: [
      {
        ratio: 'landscape',
        locale: 'ca',
        url: '/frames/christmas/13_ca_fantasy_landscape_animals_adventure.png',
        style: 'fantasy',
      },
      {
        ratio: 'portrait',
        locale: 'ca',
        url: '/frames/christmas/13_ca_fantasy_portrait_animals_adventure.png',
        style: 'fantasy',
      },
      {
        ratio: 'square',
        locale: 'ca',
        url: '/frames/christmas/13_ca_fantasy_square_animals_adventure.png',
        style: 'fantasy',
      },
      {
        ratio: 'landscape',
        locale: 'en',
        url: '/frames/christmas/13_en_fantasy_landscape_animals_adventure.png',
        style: 'fantasy',
      },
      {
        ratio: 'portrait',
        locale: 'en',
        url: '/frames/christmas/13_en_fantasy_portrait_animals_adventure.png',
        style: 'fantasy',
      },
      {
        ratio: 'square',
        locale: 'en',
        url: '/frames/christmas/13_en_fantasy_square_animals_adventure.png',
        style: 'fantasy',
      },
      {
        ratio: 'landscape',
        locale: 'es',
        url: '/frames/christmas/13_es_fantasy_landscape_animals_adventure.png',
        style: 'fantasy',
      },
      {
        ratio: 'portrait',
        locale: 'es',
        url: '/frames/christmas/13_es_fantasy_portrait_animals_adventure.png',
        style: 'fantasy',
      },
      {
        ratio: 'square',
        locale: 'es',
        url: '/frames/christmas/13_es_fantasy_square_animals_adventure.png',
        style: 'fantasy',
      },
      {
        ratio: 'landscape',
        locale: 'fr',
        url: '/frames/christmas/13_fr_fantasy_landscape_animals_adventure.png',
        style: 'fantasy',
      },
      {
        ratio: 'portrait',
        locale: 'fr',
        url: '/frames/christmas/13_fr_fantasy_portrait_animals_adventure.png',
        style: 'fantasy',
      },
      {
        ratio: 'square',
        locale: 'fr',
        url: '/frames/christmas/13_fr_fantasy_square_animals_adventure.png',
        style: 'fantasy',
      },
      {
        ratio: 'landscape',
        locale: 'it',
        url: '/frames/christmas/13_it_fantasy_landscape_animals_adventure.png',
        style: 'fantasy',
      },
      {
        ratio: 'portrait',
        locale: 'it',
        url: '/frames/christmas/13_it_fantasy_portrait_animals_adventure.png',
        style: 'fantasy',
      },
      {
        ratio: 'square',
        locale: 'it',
        url: '/frames/christmas/13_it_fantasy_square_animals_adventure.png',
        style: 'fantasy',
      },
      {
        ratio: 'landscape',
        locale: 'pt',
        url: '/frames/christmas/13_pt_fantasy_landscape_animals_adventure.png',
        style: 'fantasy',
      },
      {
        ratio: 'portrait',
        locale: 'pt',
        url: '/frames/christmas/13_pt_fantasy_portrait_animals_adventure.png',
        style: 'fantasy',
      },
      {
        ratio: 'square',
        locale: 'pt',
        url: '/frames/christmas/13_pt_fantasy_square_animals_adventure.png',
        style: 'fantasy',
      },
    ],
    style: 'fantasy',
    id: 13,
  },
  {
    categories: ['animals', 'adventure'],
    images: [
      {
        ratio: 'landscape',
        locale: 'ca',
        url: '/frames/christmas/14_ca_lego_landscape_animals_adventure.png',
        style: 'lego',
      },
      {
        ratio: 'portrait',
        locale: 'ca',
        url: '/frames/christmas/14_ca_lego_portrait_animals_adventure.png',
        style: 'lego',
      },
      {
        ratio: 'square',
        locale: 'ca',
        url: '/frames/christmas/14_ca_lego_square_animals_adventure.png',
        style: 'lego',
      },
      {
        ratio: 'landscape',
        locale: 'en',
        url: '/frames/christmas/14_en_lego_landscape_animals_adventure.png',
        style: 'lego',
      },
      {
        ratio: 'portrait',
        locale: 'en',
        url: '/frames/christmas/14_en_lego_portrait_animals_adventure.png',
        style: 'lego',
      },
      {
        ratio: 'square',
        locale: 'en',
        url: '/frames/christmas/14_en_lego_square_animals_adventure.png',
        style: 'lego',
      },
      {
        ratio: 'landscape',
        locale: 'es',
        url: '/frames/christmas/14_es_lego_landscape_animals_adventure.png',
        style: 'lego',
      },
      {
        ratio: 'portrait',
        locale: 'es',
        url: '/frames/christmas/14_es_lego_portrait_animals_adventure.png',
        style: 'lego',
      },
      {
        ratio: 'square',
        locale: 'es',
        url: '/frames/christmas/14_es_lego_square_animals_adventure.png',
        style: 'lego',
      },
      {
        ratio: 'landscape',
        locale: 'fr',
        url: '/frames/christmas/14_fr_lego_landscape_animals_adventure.png',
        style: 'lego',
      },
      {
        ratio: 'portrait',
        locale: 'fr',
        url: '/frames/christmas/14_fr_lego_portrait_animals_adventure.png',
        style: 'lego',
      },
      {
        ratio: 'square',
        locale: 'fr',
        url: '/frames/christmas/14_fr_lego_square_animals_adventure.png',
        style: 'lego',
      },
      {
        ratio: 'landscape',
        locale: 'it',
        url: '/frames/christmas/14_it_lego_landscape_animals_adventure.png',
        style: 'lego',
      },
      {
        ratio: 'portrait',
        locale: 'it',
        url: '/frames/christmas/14_it_lego_portrait_animals_adventure.png',
        style: 'lego',
      },
      {
        ratio: 'square',
        locale: 'it',
        url: '/frames/christmas/14_it_lego_square_animals_adventure.png',
        style: 'lego',
      },
      {
        ratio: 'landscape',
        locale: 'pt',
        url: '/frames/christmas/14_pt_lego_landscape_animals_adventure.png',
        style: 'lego',
      },
      {
        ratio: 'portrait',
        locale: 'pt',
        url: '/frames/christmas/14_pt_lego_portrait_animals_adventure.png',
        style: 'lego',
      },
      {
        ratio: 'square',
        locale: 'pt',
        url: '/frames/christmas/14_pt_lego_square_animals_adventure.png',
        style: 'lego',
      },
    ],
    style: 'lego',
    id: 14,
  },
  {
    categories: ['animals', 'adventure'],
    images: [
      {
        ratio: 'landscape',
        locale: 'ca',
        url: '/frames/christmas/15_ca_general_landscape_animals_adventure.png',
        style: 'general',
      },
      {
        ratio: 'portrait',
        locale: 'ca',
        url: '/frames/christmas/15_ca_general_portrait_animals_adventure.png',
        style: 'general',
      },
      {
        ratio: 'square',
        locale: 'ca',
        url: '/frames/christmas/15_ca_general_square_animals_adventure.png',
        style: 'general',
      },
      {
        ratio: 'landscape',
        locale: 'en',
        url: '/frames/christmas/15_en_general_landscape_animals_adventure.png',
        style: 'general',
      },
      {
        ratio: 'portrait',
        locale: 'en',
        url: '/frames/christmas/15_en_general_portrait_animals_adventure.png',
        style: 'general',
      },
      {
        ratio: 'square',
        locale: 'en',
        url: '/frames/christmas/15_en_general_square_animals_adventure.png',
        style: 'general',
      },
      {
        ratio: 'landscape',
        locale: 'es',
        url: '/frames/christmas/15_es_general_landscape_animals_adventure.png',
        style: 'general',
      },
      {
        ratio: 'portrait',
        locale: 'es',
        url: '/frames/christmas/15_es_general_portrait_animals_adventure.png',
        style: 'general',
      },
      {
        ratio: 'square',
        locale: 'es',
        url: '/frames/christmas/15_es_general_square_animals_adventure.png',
        style: 'general',
      },
      {
        ratio: 'landscape',
        locale: 'fr',
        url: '/frames/christmas/15_fr_general_landscape_animals_adventure.png',
        style: 'general',
      },
      {
        ratio: 'portrait',
        locale: 'fr',
        url: '/frames/christmas/15_fr_general_portrait_animals_adventure.png',
        style: 'general',
      },
      {
        ratio: 'square',
        locale: 'fr',
        url: '/frames/christmas/15_fr_general_square_animals_adventure.png',
        style: 'general',
      },
      {
        ratio: 'landscape',
        locale: 'it',
        url: '/frames/christmas/15_it_general_landscape_animals_adventure.png',
        style: 'general',
      },
      {
        ratio: 'portrait',
        locale: 'it',
        url: '/frames/christmas/15_it_general_portrait_animals_adventure.png',
        style: 'general',
      },
      {
        ratio: 'square',
        locale: 'it',
        url: '/frames/christmas/15_it_general_square_animals_adventure.png',
        style: 'general',
      },
      {
        ratio: 'landscape',
        locale: 'pt',
        url: '/frames/christmas/15_pt_general_landscape_animals_adventure.png',
        style: 'general',
      },
      {
        ratio: 'portrait',
        locale: 'pt',
        url: '/frames/christmas/15_pt_general_portrait_animals_adventure.png',
        style: 'general',
      },
      {
        ratio: 'square',
        locale: 'pt',
        url: '/frames/christmas/15_pt_general_square_animals_adventure.png',
        style: 'general',
      },
    ],
    style: 'general',
    id: 15,
  },
  {
    categories: ['animals', 'music'],
    images: [
      {
        ratio: 'landscape',
        locale: 'ca',
        url: '/frames/christmas/16_ca_fantasy_landscape_animals_music.png',
        style: 'fantasy',
      },
      {
        ratio: 'portrait',
        locale: 'ca',
        url: '/frames/christmas/16_ca_fantasy_portrait_animals_music.png',
        style: 'fantasy',
      },
      {
        ratio: 'square',
        locale: 'ca',
        url: '/frames/christmas/16_ca_fantasy_square_animals_music.png',
        style: 'fantasy',
      },
      {
        ratio: 'landscape',
        locale: 'en',
        url: '/frames/christmas/16_en_fantasy_landscape_animals_music.png',
        style: 'fantasy',
      },
      {
        ratio: 'portrait',
        locale: 'en',
        url: '/frames/christmas/16_en_fantasy_portrait_animals_music.png',
        style: 'fantasy',
      },
      {
        ratio: 'square',
        locale: 'en',
        url: '/frames/christmas/16_en_fantasy_square_animals_music.png',
        style: 'fantasy',
      },
      {
        ratio: 'landscape',
        locale: 'es',
        url: '/frames/christmas/16_es_fantasy_landscape_animals_music.png',
        style: 'fantasy',
      },
      {
        ratio: 'portrait',
        locale: 'es',
        url: '/frames/christmas/16_es_fantasy_portrait_animals_music.png',
        style: 'fantasy',
      },
      {
        ratio: 'square',
        locale: 'es',
        url: '/frames/christmas/16_es_fantasy_square_animals_music.png',
        style: 'fantasy',
      },
      {
        ratio: 'landscape',
        locale: 'fr',
        url: '/frames/christmas/16_fr_fantasy_landscape_animals_music.png',
        style: 'fantasy',
      },
      {
        ratio: 'portrait',
        locale: 'fr',
        url: '/frames/christmas/16_fr_fantasy_portrait_animals_music.png',
        style: 'fantasy',
      },
      {
        ratio: 'square',
        locale: 'fr',
        url: '/frames/christmas/16_fr_fantasy_square_animals_music.png',
        style: 'fantasy',
      },
      {
        ratio: 'landscape',
        locale: 'it',
        url: '/frames/christmas/16_it_fantasy_landscape_animals_music.png',
        style: 'fantasy',
      },
      {
        ratio: 'portrait',
        locale: 'it',
        url: '/frames/christmas/16_it_fantasy_portrait_animals_music.png',
        style: 'fantasy',
      },
      {
        ratio: 'square',
        locale: 'it',
        url: '/frames/christmas/16_it_fantasy_square_animals_music.png',
        style: 'fantasy',
      },
      {
        ratio: 'landscape',
        locale: 'pt',
        url: '/frames/christmas/16_pt_fantasy_landscape_animals_music.png',
        style: 'fantasy',
      },
      {
        ratio: 'portrait',
        locale: 'pt',
        url: '/frames/christmas/16_pt_fantasy_portrait_animals_music.png',
        style: 'fantasy',
      },
      {
        ratio: 'square',
        locale: 'pt',
        url: '/frames/christmas/16_pt_fantasy_square_animals_music.png',
        style: 'fantasy',
      },
    ],
    style: 'fantasy',
    id: 16,
  },
  {
    categories: ['animals', 'music'],
    images: [
      {
        ratio: 'landscape',
        locale: 'ca',
        url: '/frames/christmas/17_ca_lego_landscape_animals_music.png',
        style: 'lego',
      },
      {
        ratio: 'portrait',
        locale: 'ca',
        url: '/frames/christmas/17_ca_lego_portrait_animals_music.png',
        style: 'lego',
      },
      {
        ratio: 'square',
        locale: 'ca',
        url: '/frames/christmas/17_ca_lego_square_animals_music.png',
        style: 'lego',
      },
      {
        ratio: 'landscape',
        locale: 'en',
        url: '/frames/christmas/17_en_lego_landscape_animals_music.png',
        style: 'lego',
      },
      {
        ratio: 'portrait',
        locale: 'en',
        url: '/frames/christmas/17_en_lego_portrait_animals_music.png',
        style: 'lego',
      },
      {
        ratio: 'square',
        locale: 'en',
        url: '/frames/christmas/17_en_lego_square_animals_music.png',
        style: 'lego',
      },
      {
        ratio: 'landscape',
        locale: 'es',
        url: '/frames/christmas/17_es_lego_landscape_animals_music.png',
        style: 'lego',
      },
      {
        ratio: 'portrait',
        locale: 'es',
        url: '/frames/christmas/17_es_lego_portrait_animals_music.png',
        style: 'lego',
      },
      {
        ratio: 'square',
        locale: 'es',
        url: '/frames/christmas/17_es_lego_square_animals_music.png',
        style: 'lego',
      },
      {
        ratio: 'landscape',
        locale: 'fr',
        url: '/frames/christmas/17_fr_lego_landscape_animals_music.png',
        style: 'lego',
      },
      {
        ratio: 'portrait',
        locale: 'fr',
        url: '/frames/christmas/17_fr_lego_portrait_animals_music.png',
        style: 'lego',
      },
      {
        ratio: 'square',
        locale: 'fr',
        url: '/frames/christmas/17_fr_lego_square_animals_music.png',
        style: 'lego',
      },
      {
        ratio: 'landscape',
        locale: 'it',
        url: '/frames/christmas/17_it_lego_landscape_animals_music.png',
        style: 'lego',
      },
      {
        ratio: 'portrait',
        locale: 'it',
        url: '/frames/christmas/17_it_lego_portrait_animals_music.png',
        style: 'lego',
      },
      {
        ratio: 'square',
        locale: 'it',
        url: '/frames/christmas/17_it_lego_square_animals_music.png',
        style: 'lego',
      },
      {
        ratio: 'landscape',
        locale: 'pt',
        url: '/frames/christmas/17_pt_lego_landscape_animals_music.png',
        style: 'lego',
      },
      {
        ratio: 'portrait',
        locale: 'pt',
        url: '/frames/christmas/17_pt_lego_portrait_animals_music.png',
        style: 'lego',
      },
      {
        ratio: 'square',
        locale: 'pt',
        url: '/frames/christmas/17_pt_lego_square_animals_music.png',
        style: 'lego',
      },
    ],
    style: 'lego',
    id: 17,
  },
  {
    categories: ['animals', 'music'],
    images: [
      {
        ratio: 'landscape',
        locale: 'ca',
        url: '/frames/christmas/18_ca_general_landscape_animals_music.png',
        style: 'general',
      },
      {
        ratio: 'portrait',
        locale: 'ca',
        url: '/frames/christmas/18_ca_general_portrait_animals_music.png',
        style: 'general',
      },
      {
        ratio: 'square',
        locale: 'ca',
        url: '/frames/christmas/18_ca_general_square_animals_music.png',
        style: 'general',
      },
      {
        ratio: 'landscape',
        locale: 'en',
        url: '/frames/christmas/18_en_general_landscape_animals_music.png',
        style: 'general',
      },
      {
        ratio: 'portrait',
        locale: 'en',
        url: '/frames/christmas/18_en_general_portrait_animals_music.png',
        style: 'general',
      },
      {
        ratio: 'square',
        locale: 'en',
        url: '/frames/christmas/18_en_general_square_animals_music.png',
        style: 'general',
      },
      {
        ratio: 'landscape',
        locale: 'es',
        url: '/frames/christmas/18_es_general_landscape_animals_music.png',
        style: 'general',
      },
      {
        ratio: 'portrait',
        locale: 'es',
        url: '/frames/christmas/18_es_general_portrait_animals_music.png',
        style: 'general',
      },
      {
        ratio: 'square',
        locale: 'es',
        url: '/frames/christmas/18_es_general_square_animals_music.png',
        style: 'general',
      },
      {
        ratio: 'landscape',
        locale: 'fr',
        url: '/frames/christmas/18_fr_general_landscape_animals_music.png',
        style: 'general',
      },
      {
        ratio: 'portrait',
        locale: 'fr',
        url: '/frames/christmas/18_fr_general_portrait_animals_music.png',
        style: 'general',
      },
      {
        ratio: 'square',
        locale: 'fr',
        url: '/frames/christmas/18_fr_general_square_animals_music.png',
        style: 'general',
      },
      {
        ratio: 'landscape',
        locale: 'it',
        url: '/frames/christmas/18_it_general_landscape_animals_music.png',
        style: 'general',
      },
      {
        ratio: 'portrait',
        locale: 'it',
        url: '/frames/christmas/18_it_general_portrait_animals_music.png',
        style: 'general',
      },
      {
        ratio: 'square',
        locale: 'it',
        url: '/frames/christmas/18_it_general_square_animals_music.png',
        style: 'general',
      },
      {
        ratio: 'landscape',
        locale: 'pt',
        url: '/frames/christmas/18_pt_general_landscape_animals_music.png',
        style: 'general',
      },
      {
        ratio: 'portrait',
        locale: 'pt',
        url: '/frames/christmas/18_pt_general_portrait_animals_music.png',
        style: 'general',
      },
      {
        ratio: 'square',
        locale: 'pt',
        url: '/frames/christmas/18_pt_general_square_animals_music.png',
        style: 'general',
      },
    ],
    style: 'general',
    id: 18,
  },
  {
    categories: ['animals', 'sport'],
    images: [
      {
        ratio: 'landscape',
        locale: 'ca',
        url: '/frames/christmas/19_ca_fantasy_landscape_animals_sport.png',
        style: 'fantasy',
      },
      {
        ratio: 'portrait',
        locale: 'ca',
        url: '/frames/christmas/19_ca_fantasy_portrait_animals_sport.png',
        style: 'fantasy',
      },
      {
        ratio: 'square',
        locale: 'ca',
        url: '/frames/christmas/19_ca_fantasy_square_animals_sport.png',
        style: 'fantasy',
      },
      {
        ratio: 'landscape',
        locale: 'en',
        url: '/frames/christmas/19_en_fantasy_landscape_animals_sport.png',
        style: 'fantasy',
      },
      {
        ratio: 'portrait',
        locale: 'en',
        url: '/frames/christmas/19_en_fantasy_portrait_animals_sport.png',
        style: 'fantasy',
      },
      {
        ratio: 'square',
        locale: 'en',
        url: '/frames/christmas/19_en_fantasy_square_animals_sport.png',
        style: 'fantasy',
      },
      {
        ratio: 'landscape',
        locale: 'es',
        url: '/frames/christmas/19_es_fantasy_landscape_animals_sport.png',
        style: 'fantasy',
      },
      {
        ratio: 'portrait',
        locale: 'es',
        url: '/frames/christmas/19_es_fantasy_portrait_animals_sport.png',
        style: 'fantasy',
      },
      {
        ratio: 'square',
        locale: 'es',
        url: '/frames/christmas/19_es_fantasy_square_animals_sport.png',
        style: 'fantasy',
      },
      {
        ratio: 'landscape',
        locale: 'fr',
        url: '/frames/christmas/19_fr_fantasy_landscape_animals_sport.png',
        style: 'fantasy',
      },
      {
        ratio: 'portrait',
        locale: 'fr',
        url: '/frames/christmas/19_fr_fantasy_portrait_animals_sport.png',
        style: 'fantasy',
      },
      {
        ratio: 'square',
        locale: 'fr',
        url: '/frames/christmas/19_fr_fantasy_square_animals_sport.png',
        style: 'fantasy',
      },
      {
        ratio: 'landscape',
        locale: 'it',
        url: '/frames/christmas/19_it_fantasy_landscape_animals_sport.png',
        style: 'fantasy',
      },
      {
        ratio: 'portrait',
        locale: 'it',
        url: '/frames/christmas/19_it_fantasy_portrait_animals_sport.png',
        style: 'fantasy',
      },
      {
        ratio: 'square',
        locale: 'it',
        url: '/frames/christmas/19_it_fantasy_square_animals_sport.png',
        style: 'fantasy',
      },
      {
        ratio: 'landscape',
        locale: 'pt',
        url: '/frames/christmas/19_pt_fantasy_landscape_animals_sport.png',
        style: 'fantasy',
      },
      {
        ratio: 'portrait',
        locale: 'pt',
        url: '/frames/christmas/19_pt_fantasy_portrait_animals_sport.png',
        style: 'fantasy',
      },
      {
        ratio: 'square',
        locale: 'pt',
        url: '/frames/christmas/19_pt_fantasy_square_animals_sport.png',
        style: 'fantasy',
      },
    ],
    style: 'fantasy',
    id: 19,
  },
  {
    categories: ['animals', 'sport'],
    images: [
      {
        ratio: 'landscape',
        locale: 'ca',
        url: '/frames/christmas/20_ca_lego_landscape_animals_sport.png',
        style: 'lego',
      },
      {
        ratio: 'portrait',
        locale: 'ca',
        url: '/frames/christmas/20_ca_lego_portrait_animals_sport.png',
        style: 'lego',
      },
      {
        ratio: 'square',
        locale: 'ca',
        url: '/frames/christmas/20_ca_lego_square_animals_sport.png',
        style: 'lego',
      },
      {
        ratio: 'landscape',
        locale: 'en',
        url: '/frames/christmas/20_en_lego_landscape_animals_sport.png',
        style: 'lego',
      },
      {
        ratio: 'portrait',
        locale: 'en',
        url: '/frames/christmas/20_en_lego_portrait_animals_sport.png',
        style: 'lego',
      },
      {
        ratio: 'square',
        locale: 'en',
        url: '/frames/christmas/20_en_lego_square_animals_sport.png',
        style: 'lego',
      },
      {
        ratio: 'landscape',
        locale: 'es',
        url: '/frames/christmas/20_es_lego_landscape_animals_sport.png',
        style: 'lego',
      },
      {
        ratio: 'portrait',
        locale: 'es',
        url: '/frames/christmas/20_es_lego_portrait_animals_sport.png',
        style: 'lego',
      },
      {
        ratio: 'square',
        locale: 'es',
        url: '/frames/christmas/20_es_lego_square_animals_sport.png',
        style: 'lego',
      },
      {
        ratio: 'landscape',
        locale: 'fr',
        url: '/frames/christmas/20_fr_lego_landscape_animals_sport.png',
        style: 'lego',
      },
      {
        ratio: 'portrait',
        locale: 'fr',
        url: '/frames/christmas/20_fr_lego_portrait_animals_sport.png',
        style: 'lego',
      },
      {
        ratio: 'square',
        locale: 'fr',
        url: '/frames/christmas/20_fr_lego_square_animals_sport.png',
        style: 'lego',
      },
      {
        ratio: 'landscape',
        locale: 'it',
        url: '/frames/christmas/20_it_lego_landscape_animals_sport.png',
        style: 'lego',
      },
      {
        ratio: 'portrait',
        locale: 'it',
        url: '/frames/christmas/20_it_lego_portrait_animals_sport.png',
        style: 'lego',
      },
      {
        ratio: 'square',
        locale: 'it',
        url: '/frames/christmas/20_it_lego_square_animals_sport.png',
        style: 'lego',
      },
      {
        ratio: 'landscape',
        locale: 'pt',
        url: '/frames/christmas/20_pt_lego_landscape_animals_sport.png',
        style: 'lego',
      },
      {
        ratio: 'portrait',
        locale: 'pt',
        url: '/frames/christmas/20_pt_lego_portrait_animals_sport.png',
        style: 'lego',
      },
      {
        ratio: 'square',
        locale: 'pt',
        url: '/frames/christmas/20_pt_lego_square_animals_sport.png',
        style: 'lego',
      },
    ],
    style: 'lego',
    id: 20,
  },
  {
    categories: ['animals', 'sport'],
    images: [
      {
        ratio: 'landscape',
        locale: 'ca',
        url: '/frames/christmas/21_ca_general_landscape_animals_sport.png',
        style: 'general',
      },
      {
        ratio: 'portrait',
        locale: 'ca',
        url: '/frames/christmas/21_ca_general_portrait_animals_sport.png',
        style: 'general',
      },
      {
        ratio: 'square',
        locale: 'ca',
        url: '/frames/christmas/21_ca_general_square_animals_sport.png',
        style: 'general',
      },
      {
        ratio: 'landscape',
        locale: 'en',
        url: '/frames/christmas/21_en_general_landscape_animals_sport.png',
        style: 'general',
      },
      {
        ratio: 'portrait',
        locale: 'en',
        url: '/frames/christmas/21_en_general_portrait_animals_sport.png',
        style: 'general',
      },
      {
        ratio: 'square',
        locale: 'en',
        url: '/frames/christmas/21_en_general_square_animals_sport.png',
        style: 'general',
      },
      {
        ratio: 'landscape',
        locale: 'es',
        url: '/frames/christmas/21_es_general_landscape_animals_sport.png',
        style: 'general',
      },
      {
        ratio: 'portrait',
        locale: 'es',
        url: '/frames/christmas/21_es_general_portrait_animals_sport.png',
        style: 'general',
      },
      {
        ratio: 'square',
        locale: 'es',
        url: '/frames/christmas/21_es_general_square_animals_sport.png',
        style: 'general',
      },
      {
        ratio: 'landscape',
        locale: 'fr',
        url: '/frames/christmas/21_fr_general_landscape_animals_sport.png',
        style: 'general',
      },
      {
        ratio: 'portrait',
        locale: 'fr',
        url: '/frames/christmas/21_fr_general_portrait_animals_sport.png',
        style: 'general',
      },
      {
        ratio: 'square',
        locale: 'fr',
        url: '/frames/christmas/21_fr_general_square_animals_sport.png',
        style: 'general',
      },
      {
        ratio: 'landscape',
        locale: 'it',
        url: '/frames/christmas/21_it_general_landscape_animals_sport.png',
        style: 'general',
      },
      {
        ratio: 'portrait',
        locale: 'it',
        url: '/frames/christmas/21_it_general_portrait_animals_sport.png',
        style: 'general',
      },
      {
        ratio: 'square',
        locale: 'it',
        url: '/frames/christmas/21_it_general_square_animals_sport.png',
        style: 'general',
      },
      {
        ratio: 'landscape',
        locale: 'pt',
        url: '/frames/christmas/21_pt_general_landscape_animals_sport.png',
        style: 'general',
      },
      {
        ratio: 'portrait',
        locale: 'pt',
        url: '/frames/christmas/21_pt_general_portrait_animals_sport.png',
        style: 'general',
      },
      {
        ratio: 'square',
        locale: 'pt',
        url: '/frames/christmas/21_pt_general_square_animals_sport.png',
        style: 'general',
      },
    ],
    style: 'general',
    id: 21,
  },
  {
    categories: ['adventure', 'music'],
    images: [
      {
        ratio: 'landscape',
        locale: 'ca',
        url: '/frames/christmas/22_ca_fantasy_landscape_adventure_music.png',
        style: 'fantasy',
      },
      {
        ratio: 'portrait',
        locale: 'ca',
        url: '/frames/christmas/22_ca_fantasy_portrait_adventure_music.png',
        style: 'fantasy',
      },
      {
        ratio: 'square',
        locale: 'ca',
        url: '/frames/christmas/22_ca_fantasy_square_adventure_music.png',
        style: 'fantasy',
      },
      {
        ratio: 'landscape',
        locale: 'en',
        url: '/frames/christmas/22_en_fantasy_landscape_adventure_music.png',
        style: 'fantasy',
      },
      {
        ratio: 'portrait',
        locale: 'en',
        url: '/frames/christmas/22_en_fantasy_portrait_adventure_music.png',
        style: 'fantasy',
      },
      {
        ratio: 'square',
        locale: 'en',
        url: '/frames/christmas/22_en_fantasy_square_adventure_music.png',
        style: 'fantasy',
      },
      {
        ratio: 'landscape',
        locale: 'es',
        url: '/frames/christmas/22_es_fantasy_landscape_adventure_music.png',
        style: 'fantasy',
      },
      {
        ratio: 'portrait',
        locale: 'es',
        url: '/frames/christmas/22_es_fantasy_portrait_adventure_music.png',
        style: 'fantasy',
      },
      {
        ratio: 'square',
        locale: 'es',
        url: '/frames/christmas/22_es_fantasy_square_adventure_music.png',
        style: 'fantasy',
      },
      {
        ratio: 'landscape',
        locale: 'fr',
        url: '/frames/christmas/22_fr_fantasy_landscape_adventure_music.png',
        style: 'fantasy',
      },
      {
        ratio: 'portrait',
        locale: 'fr',
        url: '/frames/christmas/22_fr_fantasy_portrait_adventure_music.png',
        style: 'fantasy',
      },
      {
        ratio: 'square',
        locale: 'fr',
        url: '/frames/christmas/22_fr_fantasy_square_adventure_music.png',
        style: 'fantasy',
      },
      {
        ratio: 'landscape',
        locale: 'it',
        url: '/frames/christmas/22_it_fantasy_landscape_adventure_music.png',
        style: 'fantasy',
      },
      {
        ratio: 'portrait',
        locale: 'it',
        url: '/frames/christmas/22_it_fantasy_portrait_adventure_music.png',
        style: 'fantasy',
      },
      {
        ratio: 'square',
        locale: 'it',
        url: '/frames/christmas/22_it_fantasy_square_adventure_music.png',
        style: 'fantasy',
      },
      {
        ratio: 'landscape',
        locale: 'pt',
        url: '/frames/christmas/22_pt_fantasy_landscape_adventure_music.png',
        style: 'fantasy',
      },
      {
        ratio: 'portrait',
        locale: 'pt',
        url: '/frames/christmas/22_pt_fantasy_portrait_adventure_music.png',
        style: 'fantasy',
      },
      {
        ratio: 'square',
        locale: 'pt',
        url: '/frames/christmas/22_pt_fantasy_square_adventure_music.png',
        style: 'fantasy',
      },
    ],
    style: 'fantasy',
    id: 22,
  },
  {
    categories: ['adventure', 'music'],
    images: [
      {
        ratio: 'landscape',
        locale: 'ca',
        url: '/frames/christmas/23_ca_lego_landscape_adventure_music.png',
        style: 'lego',
      },
      {
        ratio: 'portrait',
        locale: 'ca',
        url: '/frames/christmas/23_ca_lego_portrait_adventure_music.png',
        style: 'lego',
      },
      {
        ratio: 'square',
        locale: 'ca',
        url: '/frames/christmas/23_ca_lego_square_adventure_music.png',
        style: 'lego',
      },
      {
        ratio: 'landscape',
        locale: 'en',
        url: '/frames/christmas/23_en_lego_landscape_adventure_music.png',
        style: 'lego',
      },
      {
        ratio: 'portrait',
        locale: 'en',
        url: '/frames/christmas/23_en_lego_portrait_adventure_music.png',
        style: 'lego',
      },
      {
        ratio: 'square',
        locale: 'en',
        url: '/frames/christmas/23_en_lego_square_adventure_music.png',
        style: 'lego',
      },
      {
        ratio: 'landscape',
        locale: 'es',
        url: '/frames/christmas/23_es_lego_landscape_adventure_music.png',
        style: 'lego',
      },
      {
        ratio: 'portrait',
        locale: 'es',
        url: '/frames/christmas/23_es_lego_portrait_adventure_music.png',
        style: 'lego',
      },
      {
        ratio: 'square',
        locale: 'es',
        url: '/frames/christmas/23_es_lego_square_adventure_music.png',
        style: 'lego',
      },
      {
        ratio: 'landscape',
        locale: 'fr',
        url: '/frames/christmas/23_fr_lego_landscape_adventure_music.png',
        style: 'lego',
      },
      {
        ratio: 'portrait',
        locale: 'fr',
        url: '/frames/christmas/23_fr_lego_portrait_adventure_music.png',
        style: 'lego',
      },
      {
        ratio: 'square',
        locale: 'fr',
        url: '/frames/christmas/23_fr_lego_square_adventure_music.png',
        style: 'lego',
      },
      {
        ratio: 'landscape',
        locale: 'it',
        url: '/frames/christmas/23_it_lego_landscape_adventure_music.png',
        style: 'lego',
      },
      {
        ratio: 'portrait',
        locale: 'it',
        url: '/frames/christmas/23_it_lego_portrait_adventure_music.png',
        style: 'lego',
      },
      {
        ratio: 'square',
        locale: 'it',
        url: '/frames/christmas/23_it_lego_square_adventure_music.png',
        style: 'lego',
      },
      {
        ratio: 'landscape',
        locale: 'pt',
        url: '/frames/christmas/23_pt_lego_landscape_adventure_music.png',
        style: 'lego',
      },
      {
        ratio: 'portrait',
        locale: 'pt',
        url: '/frames/christmas/23_pt_lego_portrait_adventure_music.png',
        style: 'lego',
      },
      {
        ratio: 'square',
        locale: 'pt',
        url: '/frames/christmas/23_pt_lego_square_adventure_music.png',
        style: 'lego',
      },
    ],
    style: 'lego',
    id: 23,
  },
  {
    categories: ['adventure', 'music'],
    images: [
      {
        ratio: 'landscape',
        locale: 'ca',
        url: '/frames/christmas/24_ca_general_landscape_adventure_music.png',
        style: 'general',
      },
      {
        ratio: 'portrait',
        locale: 'ca',
        url: '/frames/christmas/24_ca_general_portrait_adventure_music.png',
        style: 'general',
      },
      {
        ratio: 'square',
        locale: 'ca',
        url: '/frames/christmas/24_ca_general_square_adventure_music.png',
        style: 'general',
      },
      {
        ratio: 'landscape',
        locale: 'en',
        url: '/frames/christmas/24_en_general_landscape_adventure_music.png',
        style: 'general',
      },
      {
        ratio: 'portrait',
        locale: 'en',
        url: '/frames/christmas/24_en_general_portrait_adventure_music.png',
        style: 'general',
      },
      {
        ratio: 'square',
        locale: 'en',
        url: '/frames/christmas/24_en_general_square_adventure_music.png',
        style: 'general',
      },
      {
        ratio: 'landscape',
        locale: 'es',
        url: '/frames/christmas/24_es_general_landscape_adventure_music.png',
        style: 'general',
      },
      {
        ratio: 'portrait',
        locale: 'es',
        url: '/frames/christmas/24_es_general_portrait_adventure_music.png',
        style: 'general',
      },
      {
        ratio: 'square',
        locale: 'es',
        url: '/frames/christmas/24_es_general_square_adventure_music.png',
        style: 'general',
      },
      {
        ratio: 'landscape',
        locale: 'fr',
        url: '/frames/christmas/24_fr_general_landscape_adventure_music.png',
        style: 'general',
      },
      {
        ratio: 'portrait',
        locale: 'fr',
        url: '/frames/christmas/24_fr_general_portrait_adventure_music.png',
        style: 'general',
      },
      {
        ratio: 'square',
        locale: 'fr',
        url: '/frames/christmas/24_fr_general_square_adventure_music.png',
        style: 'general',
      },
      {
        ratio: 'landscape',
        locale: 'it',
        url: '/frames/christmas/24_it_general_landscape_adventure_music.png',
        style: 'general',
      },
      {
        ratio: 'portrait',
        locale: 'it',
        url: '/frames/christmas/24_it_general_portrait_adventure_music.png',
        style: 'general',
      },
      {
        ratio: 'square',
        locale: 'it',
        url: '/frames/christmas/24_it_general_square_adventure_music.png',
        style: 'general',
      },
      {
        ratio: 'landscape',
        locale: 'pt',
        url: '/frames/christmas/24_pt_general_landscape_adventure_music.png',
        style: 'general',
      },
      {
        ratio: 'portrait',
        locale: 'pt',
        url: '/frames/christmas/24_pt_general_portrait_adventure_music.png',
        style: 'general',
      },
      {
        ratio: 'square',
        locale: 'pt',
        url: '/frames/christmas/24_pt_general_square_adventure_music.png',
        style: 'general',
      },
    ],
    style: 'general',
    id: 24,
  },
  {
    categories: ['music', 'sport'],
    images: [
      {
        ratio: 'landscape',
        locale: 'ca',
        url: '/frames/christmas/25_ca_fantasy_landscape_music_sport.png',
        style: 'fantasy',
      },
      {
        ratio: 'portrait',
        locale: 'ca',
        url: '/frames/christmas/25_ca_fantasy_portrait_music_sport.png',
        style: 'fantasy',
      },
      {
        ratio: 'square',
        locale: 'ca',
        url: '/frames/christmas/25_ca_fantasy_square_music_sport.png',
        style: 'fantasy',
      },
      {
        ratio: 'landscape',
        locale: 'en',
        url: '/frames/christmas/25_en_fantasy_landscape_music_sport.png',
        style: 'fantasy',
      },
      {
        ratio: 'portrait',
        locale: 'en',
        url: '/frames/christmas/25_en_fantasy_portrait_music_sport.png',
        style: 'fantasy',
      },
      {
        ratio: 'square',
        locale: 'en',
        url: '/frames/christmas/25_en_fantasy_square_music_sport.png',
        style: 'fantasy',
      },
      {
        ratio: 'landscape',
        locale: 'es',
        url: '/frames/christmas/25_es_fantasy_landscape_music_sport.png',
        style: 'fantasy',
      },
      {
        ratio: 'portrait',
        locale: 'es',
        url: '/frames/christmas/25_es_fantasy_portrait_music_sport.png',
        style: 'fantasy',
      },
      {
        ratio: 'square',
        locale: 'es',
        url: '/frames/christmas/25_es_fantasy_square_music_sport.png',
        style: 'fantasy',
      },
      {
        ratio: 'landscape',
        locale: 'fr',
        url: '/frames/christmas/25_fr_fantasy_landscape_music_sport.png',
        style: 'fantasy',
      },
      {
        ratio: 'portrait',
        locale: 'fr',
        url: '/frames/christmas/25_fr_fantasy_portrait_music_sport.png',
        style: 'fantasy',
      },
      {
        ratio: 'square',
        locale: 'fr',
        url: '/frames/christmas/25_fr_fantasy_square_music_sport.png',
        style: 'fantasy',
      },
      {
        ratio: 'landscape',
        locale: 'it',
        url: '/frames/christmas/25_it_fantasy_landscape_music_sport.png',
        style: 'fantasy',
      },
      {
        ratio: 'portrait',
        locale: 'it',
        url: '/frames/christmas/25_it_fantasy_portrait_music_sport.png',
        style: 'fantasy',
      },
      {
        ratio: 'square',
        locale: 'it',
        url: '/frames/christmas/25_it_fantasy_square_music_sport.png',
        style: 'fantasy',
      },
      {
        ratio: 'landscape',
        locale: 'pt',
        url: '/frames/christmas/25_pt_fantasy_landscape_music_sport.png',
        style: 'fantasy',
      },
      {
        ratio: 'portrait',
        locale: 'pt',
        url: '/frames/christmas/25_pt_fantasy_portrait_music_sport.png',
        style: 'fantasy',
      },
      {
        ratio: 'square',
        locale: 'pt',
        url: '/frames/christmas/25_pt_fantasy_square_music_sport.png',
        style: 'fantasy',
      },
    ],
    style: 'fantasy',
    id: 25,
  },
  {
    categories: ['music', 'sport'],
    images: [
      {
        ratio: 'landscape',
        locale: 'ca',
        url: '/frames/christmas/26_ca_lego_landscape_music_sport.png',
        style: 'lego',
      },
      {
        ratio: 'portrait',
        locale: 'ca',
        url: '/frames/christmas/26_ca_lego_portrait_music_sport.png',
        style: 'lego',
      },
      {
        ratio: 'square',
        locale: 'ca',
        url: '/frames/christmas/26_ca_lego_square_music_sport.png',
        style: 'lego',
      },
      {
        ratio: 'landscape',
        locale: 'en',
        url: '/frames/christmas/26_en_lego_landscape_music_sport.png',
        style: 'lego',
      },
      {
        ratio: 'portrait',
        locale: 'en',
        url: '/frames/christmas/26_en_lego_portrait_music_sport.png',
        style: 'lego',
      },
      {
        ratio: 'square',
        locale: 'en',
        url: '/frames/christmas/26_en_lego_square_music_sport.png',
        style: 'lego',
      },
      {
        ratio: 'landscape',
        locale: 'es',
        url: '/frames/christmas/26_es_lego_landscape_music_sport.png',
        style: 'lego',
      },
      {
        ratio: 'portrait',
        locale: 'es',
        url: '/frames/christmas/26_es_lego_portrait_music_sport.png',
        style: 'lego',
      },
      {
        ratio: 'square',
        locale: 'es',
        url: '/frames/christmas/26_es_lego_square_music_sport.png',
        style: 'lego',
      },
      {
        ratio: 'landscape',
        locale: 'fr',
        url: '/frames/christmas/26_fr_lego_landscape_music_sport.png',
        style: 'lego',
      },
      {
        ratio: 'portrait',
        locale: 'fr',
        url: '/frames/christmas/26_fr_lego_portrait_music_sport.png',
        style: 'lego',
      },
      {
        ratio: 'square',
        locale: 'fr',
        url: '/frames/christmas/26_fr_lego_square_music_sport.png',
        style: 'lego',
      },
      {
        ratio: 'landscape',
        locale: 'it',
        url: '/frames/christmas/26_it_lego_landscape_music_sport.png',
        style: 'lego',
      },
      {
        ratio: 'portrait',
        locale: 'it',
        url: '/frames/christmas/26_it_lego_portrait_music_sport.png',
        style: 'lego',
      },
      {
        ratio: 'square',
        locale: 'it',
        url: '/frames/christmas/26_it_lego_square_music_sport.png',
        style: 'lego',
      },
      {
        ratio: 'landscape',
        locale: 'pt',
        url: '/frames/christmas/26_pt_lego_landscape_music_sport.png',
        style: 'lego',
      },
      {
        ratio: 'portrait',
        locale: 'pt',
        url: '/frames/christmas/26_pt_lego_portrait_music_sport.png',
        style: 'lego',
      },
      {
        ratio: 'square',
        locale: 'pt',
        url: '/frames/christmas/26_pt_lego_square_music_sport.png',
        style: 'lego',
      },
    ],
    style: 'lego',
    id: 26,
  },
  {
    categories: ['music', 'sport'],
    images: [
      {
        ratio: 'landscape',
        locale: 'ca',
        url: '/frames/christmas/27_ca_general_landscape_music_sport.png',
        style: 'general',
      },
      {
        ratio: 'portrait',
        locale: 'ca',
        url: '/frames/christmas/27_ca_general_portrait_music_sport.png',
        style: 'general',
      },
      {
        ratio: 'square',
        locale: 'ca',
        url: '/frames/christmas/27_ca_general_square_music_sport.png',
        style: 'general',
      },
      {
        ratio: 'landscape',
        locale: 'en',
        url: '/frames/christmas/27_en_general_landscape_music_sport.png',
        style: 'general',
      },
      {
        ratio: 'portrait',
        locale: 'en',
        url: '/frames/christmas/27_en_general_portrait_music_sport.png',
        style: 'general',
      },
      {
        ratio: 'square',
        locale: 'en',
        url: '/frames/christmas/27_en_general_square_music_sport.png',
        style: 'general',
      },
      {
        ratio: 'landscape',
        locale: 'es',
        url: '/frames/christmas/27_es_general_landscape_music_sport.png',
        style: 'general',
      },
      {
        ratio: 'portrait',
        locale: 'es',
        url: '/frames/christmas/27_es_general_portrait_music_sport.png',
        style: 'general',
      },
      {
        ratio: 'square',
        locale: 'es',
        url: '/frames/christmas/27_es_general_square_music_sport.png',
        style: 'general',
      },
      {
        ratio: 'landscape',
        locale: 'fr',
        url: '/frames/christmas/27_fr_general_landscape_music_sport.png',
        style: 'general',
      },
      {
        ratio: 'portrait',
        locale: 'fr',
        url: '/frames/christmas/27_fr_general_portrait_music_sport.png',
        style: 'general',
      },
      {
        ratio: 'square',
        locale: 'fr',
        url: '/frames/christmas/27_fr_general_square_music_sport.png',
        style: 'general',
      },
      {
        ratio: 'landscape',
        locale: 'it',
        url: '/frames/christmas/27_it_general_landscape_music_sport.png',
        style: 'general',
      },
      {
        ratio: 'portrait',
        locale: 'it',
        url: '/frames/christmas/27_it_general_portrait_music_sport.png',
        style: 'general',
      },
      {
        ratio: 'square',
        locale: 'it',
        url: '/frames/christmas/27_it_general_square_music_sport.png',
        style: 'general',
      },
      {
        ratio: 'landscape',
        locale: 'pt',
        url: '/frames/christmas/27_pt_general_landscape_music_sport.png',
        style: 'general',
      },
      {
        ratio: 'portrait',
        locale: 'pt',
        url: '/frames/christmas/27_pt_general_portrait_music_sport.png',
        style: 'general',
      },
      {
        ratio: 'square',
        locale: 'pt',
        url: '/frames/christmas/27_pt_general_square_music_sport.png',
        style: 'general',
      },
    ],
    style: 'general',
    id: 27,
  },
  {
    categories: ['adventure', 'sport'],
    images: [
      {
        ratio: 'landscape',
        locale: 'ca',
        url: '/frames/christmas/28_ca_fantasy_landscape_adventure_sport.png',
        style: 'fantasy',
      },
      {
        ratio: 'portrait',
        locale: 'ca',
        url: '/frames/christmas/28_ca_fantasy_portrait_adventure_sport.png',
        style: 'fantasy',
      },
      {
        ratio: 'square',
        locale: 'ca',
        url: '/frames/christmas/28_ca_fantasy_square_adventure_sport.png',
        style: 'fantasy',
      },
      {
        ratio: 'landscape',
        locale: 'en',
        url: '/frames/christmas/28_en_fantasy_landscape_adventure_sport.png',
        style: 'fantasy',
      },
      {
        ratio: 'portrait',
        locale: 'en',
        url: '/frames/christmas/28_en_fantasy_portrait_adventure_sport.png',
        style: 'fantasy',
      },
      {
        ratio: 'square',
        locale: 'en',
        url: '/frames/christmas/28_en_fantasy_square_adventure_sport.png',
        style: 'fantasy',
      },
      {
        ratio: 'landscape',
        locale: 'es',
        url: '/frames/christmas/28_es_fantasy_landscape_adventure_sport.png',
        style: 'fantasy',
      },
      {
        ratio: 'portrait',
        locale: 'es',
        url: '/frames/christmas/28_es_fantasy_portrait_adventure_sport.png',
        style: 'fantasy',
      },
      {
        ratio: 'square',
        locale: 'es',
        url: '/frames/christmas/28_es_fantasy_square_adventure_sport.png',
        style: 'fantasy',
      },
      {
        ratio: 'landscape',
        locale: 'fr',
        url: '/frames/christmas/28_fr_fantasy_landscape_adventure_sport.png',
        style: 'fantasy',
      },
      {
        ratio: 'portrait',
        locale: 'fr',
        url: '/frames/christmas/28_fr_fantasy_portrait_adventure_sport.png',
        style: 'fantasy',
      },
      {
        ratio: 'square',
        locale: 'fr',
        url: '/frames/christmas/28_fr_fantasy_square_adventure_sport.png',
        style: 'fantasy',
      },
      {
        ratio: 'landscape',
        locale: 'it',
        url: '/frames/christmas/28_it_fantasy_landscape_adventure_sport.png',
        style: 'fantasy',
      },
      {
        ratio: 'portrait',
        locale: 'it',
        url: '/frames/christmas/28_it_fantasy_portrait_adventure_sport.png',
        style: 'fantasy',
      },
      {
        ratio: 'square',
        locale: 'it',
        url: '/frames/christmas/28_it_fantasy_square_adventure_sport.png',
        style: 'fantasy',
      },
      {
        ratio: 'landscape',
        locale: 'pt',
        url: '/frames/christmas/28_pt_fantasy_landscape_adventure_sport.png',
        style: 'fantasy',
      },
      {
        ratio: 'portrait',
        locale: 'pt',
        url: '/frames/christmas/28_pt_fantasy_portrait_adventure_sport.png',
        style: 'fantasy',
      },
      {
        ratio: 'square',
        locale: 'pt',
        url: '/frames/christmas/28_pt_fantasy_square_adventure_sport.png',
        style: 'fantasy',
      },
    ],
    style: 'fantasy',
    id: 28,
  },
  {
    categories: ['adventure', 'sport'],
    images: [
      {
        ratio: 'landscape',
        locale: 'ca',
        url: '/frames/christmas/29_ca_lego_landscape_adventure_sport.png',
        style: 'lego',
      },
      {
        ratio: 'portrait',
        locale: 'ca',
        url: '/frames/christmas/29_ca_lego_portrait_adventure_sport.png',
        style: 'lego',
      },
      {
        ratio: 'square',
        locale: 'ca',
        url: '/frames/christmas/29_ca_lego_square_adventure_sport.png',
        style: 'lego',
      },
      {
        ratio: 'landscape',
        locale: 'en',
        url: '/frames/christmas/29_en_lego_landscape_adventure_sport.png',
        style: 'lego',
      },
      {
        ratio: 'portrait',
        locale: 'en',
        url: '/frames/christmas/29_en_lego_portrait_adventure_sport.png',
        style: 'lego',
      },
      {
        ratio: 'square',
        locale: 'en',
        url: '/frames/christmas/29_en_lego_square_adventure_sport.png',
        style: 'lego',
      },
      {
        ratio: 'landscape',
        locale: 'es',
        url: '/frames/christmas/29_es_lego_landscape_adventure_sport.png',
        style: 'lego',
      },
      {
        ratio: 'portrait',
        locale: 'es',
        url: '/frames/christmas/29_es_lego_portrait_adventure_sport.png',
        style: 'lego',
      },
      {
        ratio: 'square',
        locale: 'es',
        url: '/frames/christmas/29_es_lego_square_adventure_sport.png',
        style: 'lego',
      },
      {
        ratio: 'landscape',
        locale: 'fr',
        url: '/frames/christmas/29_fr_lego_landscape_adventure_sport.png',
        style: 'lego',
      },
      {
        ratio: 'portrait',
        locale: 'fr',
        url: '/frames/christmas/29_fr_lego_portrait_adventure_sport.png',
        style: 'lego',
      },
      {
        ratio: 'square',
        locale: 'fr',
        url: '/frames/christmas/29_fr_lego_square_adventure_sport.png',
        style: 'lego',
      },
      {
        ratio: 'landscape',
        locale: 'it',
        url: '/frames/christmas/29_it_lego_landscape_adventure_sport.png',
        style: 'lego',
      },
      {
        ratio: 'portrait',
        locale: 'it',
        url: '/frames/christmas/29_it_lego_portrait_adventure_sport.png',
        style: 'lego',
      },
      {
        ratio: 'square',
        locale: 'it',
        url: '/frames/christmas/29_it_lego_square_adventure_sport.png',
        style: 'lego',
      },
      {
        ratio: 'landscape',
        locale: 'pt',
        url: '/frames/christmas/29_pt_lego_landscape_adventure_sport.png',
        style: 'lego',
      },
      {
        ratio: 'portrait',
        locale: 'pt',
        url: '/frames/christmas/29_pt_lego_portrait_adventure_sport.png',
        style: 'lego',
      },
      {
        ratio: 'square',
        locale: 'pt',
        url: '/frames/christmas/29_pt_lego_square_adventure_sport.png',
        style: 'lego',
      },
    ],
    style: 'lego',
    id: 29,
  },
  {
    categories: ['adventure', 'sport'],
    images: [
      {
        ratio: 'landscape',
        locale: 'ca',
        url: '/frames/christmas/30_ca_general_landscape_adventure_sport.png',
        style: 'general',
      },
      {
        ratio: 'portrait',
        locale: 'ca',
        url: '/frames/christmas/30_ca_general_portrait_adventure_sport.png',
        style: 'general',
      },
      {
        ratio: 'square',
        locale: 'ca',
        url: '/frames/christmas/30_ca_general_square_adventure_sport.png',
        style: 'general',
      },
      {
        ratio: 'landscape',
        locale: 'en',
        url: '/frames/christmas/30_en_general_landscape_adventure_sport.png',
        style: 'general',
      },
      {
        ratio: 'portrait',
        locale: 'en',
        url: '/frames/christmas/30_en_general_portrait_adventure_sport.png',
        style: 'general',
      },
      {
        ratio: 'square',
        locale: 'en',
        url: '/frames/christmas/30_en_general_square_adventure_sport.png',
        style: 'general',
      },
      {
        ratio: 'landscape',
        locale: 'es',
        url: '/frames/christmas/30_es_general_landscape_adventure_sport.png',
        style: 'general',
      },
      {
        ratio: 'portrait',
        locale: 'es',
        url: '/frames/christmas/30_es_general_portrait_adventure_sport.png',
        style: 'general',
      },
      {
        ratio: 'square',
        locale: 'es',
        url: '/frames/christmas/30_es_general_square_adventure_sport.png',
        style: 'general',
      },
      {
        ratio: 'landscape',
        locale: 'fr',
        url: '/frames/christmas/30_fr_general_landscape_adventure_sport.png',
        style: 'general',
      },
      {
        ratio: 'portrait',
        locale: 'fr',
        url: '/frames/christmas/30_fr_general_portrait_adventure_sport.png',
        style: 'general',
      },
      {
        ratio: 'square',
        locale: 'fr',
        url: '/frames/christmas/30_fr_general_square_adventure_sport.png',
        style: 'general',
      },
      {
        ratio: 'landscape',
        locale: 'it',
        url: '/frames/christmas/30_it_general_landscape_adventure_sport.png',
        style: 'general',
      },
      {
        ratio: 'portrait',
        locale: 'it',
        url: '/frames/christmas/30_it_general_portrait_adventure_sport.png',
        style: 'general',
      },
      {
        ratio: 'square',
        locale: 'it',
        url: '/frames/christmas/30_it_general_square_adventure_sport.png',
        style: 'general',
      },
      {
        ratio: 'landscape',
        locale: 'pt',
        url: '/frames/christmas/30_pt_general_landscape_adventure_sport.png',
        style: 'general',
      },
      {
        ratio: 'portrait',
        locale: 'pt',
        url: '/frames/christmas/30_pt_general_portrait_adventure_sport.png',
        style: 'general',
      },
      {
        ratio: 'square',
        locale: 'pt',
        url: '/frames/christmas/30_pt_general_square_adventure_sport.png',
        style: 'general',
      },
    ],
    style: 'general',
    id: 30,
  },
  {
    categories: ['sport', 'adventure', 'music'],
    images: [
      {
        ratio: 'landscape',
        locale: 'ca',
        url: '/frames/christmas/31_ca_fantasy_landscape_sport_adventure_music.png',
        style: 'fantasy',
      },
      {
        ratio: 'portrait',
        locale: 'ca',
        url: '/frames/christmas/31_ca_fantasy_portrait_sport_adventure_music.png',
        style: 'fantasy',
      },
      {
        ratio: 'square',
        locale: 'ca',
        url: '/frames/christmas/31_ca_fantasy_square_sport_adventure_music.png',
        style: 'fantasy',
      },
      {
        ratio: 'landscape',
        locale: 'en',
        url: '/frames/christmas/31_en_fantasy_landscape_sport_adventure_music.png',
        style: 'fantasy',
      },
      {
        ratio: 'portrait',
        locale: 'en',
        url: '/frames/christmas/31_en_fantasy_portrait_sport_adventure_music.png',
        style: 'fantasy',
      },
      {
        ratio: 'square',
        locale: 'en',
        url: '/frames/christmas/31_en_fantasy_square_sport_adventure_music.png',
        style: 'fantasy',
      },
      {
        ratio: 'landscape',
        locale: 'es',
        url: '/frames/christmas/31_es_fantasy_landscape_sport_adventure_music.png',
        style: 'fantasy',
      },
      {
        ratio: 'portrait',
        locale: 'es',
        url: '/frames/christmas/31_es_fantasy_portrait_sport_adventure_music.png',
        style: 'fantasy',
      },
      {
        ratio: 'square',
        locale: 'es',
        url: '/frames/christmas/31_es_fantasy_square_sport_adventure_music.png',
        style: 'fantasy',
      },
      {
        ratio: 'landscape',
        locale: 'fr',
        url: '/frames/christmas/31_fr_fantasy_landscape_sport_adventure_music.png',
        style: 'fantasy',
      },
      {
        ratio: 'portrait',
        locale: 'fr',
        url: '/frames/christmas/31_fr_fantasy_portrait_sport_adventure_music.png',
        style: 'fantasy',
      },
      {
        ratio: 'square',
        locale: 'fr',
        url: '/frames/christmas/31_fr_fantasy_square_sport_adventure_music.png',
        style: 'fantasy',
      },
      {
        ratio: 'landscape',
        locale: 'it',
        url: '/frames/christmas/31_it_fantasy_landscape_sport_adventure_music.png',
        style: 'fantasy',
      },
      {
        ratio: 'portrait',
        locale: 'it',
        url: '/frames/christmas/31_it_fantasy_portrait_sport_adventure_music.png',
        style: 'fantasy',
      },
      {
        ratio: 'square',
        locale: 'it',
        url: '/frames/christmas/31_it_fantasy_square_sport_adventure_music.png',
        style: 'fantasy',
      },
      {
        ratio: 'landscape',
        locale: 'pt',
        url: '/frames/christmas/31_pt_fantasy_landscape_sport_adventure_music.png',
        style: 'fantasy',
      },
      {
        ratio: 'portrait',
        locale: 'pt',
        url: '/frames/christmas/31_pt_fantasy_portrait_sport_adventure_music.png',
        style: 'fantasy',
      },
      {
        ratio: 'square',
        locale: 'pt',
        url: '/frames/christmas/31_pt_fantasy_square_sport_adventure_music.png',
        style: 'fantasy',
      },
    ],
    style: 'fantasy',
    id: 31,
  },
  {
    categories: ['sport', 'adventure', 'music'],
    images: [
      {
        ratio: 'landscape',
        locale: 'ca',
        url: '/frames/christmas/32_ca_lego_landscape_sport_adventure_music.png',
        style: 'lego',
      },
      {
        ratio: 'portrait',
        locale: 'ca',
        url: '/frames/christmas/32_ca_lego_portrait_sport_adventure_music.png',
        style: 'lego',
      },
      {
        ratio: 'square',
        locale: 'ca',
        url: '/frames/christmas/32_ca_lego_square_sport_adventure_music.png',
        style: 'lego',
      },
      {
        ratio: 'landscape',
        locale: 'en',
        url: '/frames/christmas/32_en_lego_landscape_sport_adventure_music.png',
        style: 'lego',
      },
      {
        ratio: 'portrait',
        locale: 'en',
        url: '/frames/christmas/32_en_lego_portrait_sport_adventure_music.png',
        style: 'lego',
      },
      {
        ratio: 'square',
        locale: 'en',
        url: '/frames/christmas/32_en_lego_square_sport_adventure_music.png',
        style: 'lego',
      },
      {
        ratio: 'landscape',
        locale: 'es',
        url: '/frames/christmas/32_es_lego_landscape_sport_adventure_music.png',
        style: 'lego',
      },
      {
        ratio: 'portrait',
        locale: 'es',
        url: '/frames/christmas/32_es_lego_portrait_sport_adventure_music.png',
        style: 'lego',
      },
      {
        ratio: 'square',
        locale: 'es',
        url: '/frames/christmas/32_es_lego_square_sport_adventure_music.png',
        style: 'lego',
      },
      {
        ratio: 'landscape',
        locale: 'fr',
        url: '/frames/christmas/32_fr_lego_landscape_sport_adventure_music.png',
        style: 'lego',
      },
      {
        ratio: 'portrait',
        locale: 'fr',
        url: '/frames/christmas/32_fr_lego_portrait_sport_adventure_music.png',
        style: 'lego',
      },
      {
        ratio: 'square',
        locale: 'fr',
        url: '/frames/christmas/32_fr_lego_square_sport_adventure_music.png',
        style: 'lego',
      },
      {
        ratio: 'landscape',
        locale: 'it',
        url: '/frames/christmas/32_it_lego_landscape_sport_adventure_music.png',
        style: 'lego',
      },
      {
        ratio: 'portrait',
        locale: 'it',
        url: '/frames/christmas/32_it_lego_portrait_sport_adventure_music.png',
        style: 'lego',
      },
      {
        ratio: 'square',
        locale: 'it',
        url: '/frames/christmas/32_it_lego_square_sport_adventure_music.png',
        style: 'lego',
      },
      {
        ratio: 'landscape',
        locale: 'pt',
        url: '/frames/christmas/32_pt_lego_landscape_sport_adventure_music.png',
        style: 'lego',
      },
      {
        ratio: 'portrait',
        locale: 'pt',
        url: '/frames/christmas/32_pt_lego_portrait_sport_adventure_music.png',
        style: 'lego',
      },
      {
        ratio: 'square',
        locale: 'pt',
        url: '/frames/christmas/32_pt_lego_square_sport_adventure_music.png',
        style: 'lego',
      },
    ],
    style: 'lego',
    id: 32,
  },
  {
    categories: ['sport', 'adventure', 'music'],
    images: [
      {
        ratio: 'landscape',
        locale: 'ca',
        url: '/frames/christmas/33_ca_general_landscape_sport_adventure_music.png',
        style: 'general',
      },
      {
        ratio: 'portrait',
        locale: 'ca',
        url: '/frames/christmas/33_ca_general_portrait_sport_adventure_music.png',
        style: 'general',
      },
      {
        ratio: 'square',
        locale: 'ca',
        url: '/frames/christmas/33_ca_general_square_sport_adventure_music.png',
        style: 'general',
      },
      {
        ratio: 'landscape',
        locale: 'en',
        url: '/frames/christmas/33_en_general_landscape_sport_adventure_music.png',
        style: 'general',
      },
      {
        ratio: 'portrait',
        locale: 'en',
        url: '/frames/christmas/33_en_general_portrait_sport_adventure_music.png',
        style: 'general',
      },
      {
        ratio: 'square',
        locale: 'en',
        url: '/frames/christmas/33_en_general_square_sport_adventure_music.png',
        style: 'general',
      },
      {
        ratio: 'landscape',
        locale: 'es',
        url: '/frames/christmas/33_es_general_landscape_sport_adventure_music.png',
        style: 'general',
      },
      {
        ratio: 'portrait',
        locale: 'es',
        url: '/frames/christmas/33_es_general_portrait_sport_adventure_music.png',
        style: 'general',
      },
      {
        ratio: 'square',
        locale: 'es',
        url: '/frames/christmas/33_es_general_square_sport_adventure_music.png',
        style: 'general',
      },
      {
        ratio: 'landscape',
        locale: 'fr',
        url: '/frames/christmas/33_fr_general_landscape_sport_adventure_music.png',
        style: 'general',
      },
      {
        ratio: 'portrait',
        locale: 'fr',
        url: '/frames/christmas/33_fr_general_portrait_sport_adventure_music.png',
        style: 'general',
      },
      {
        ratio: 'square',
        locale: 'fr',
        url: '/frames/christmas/33_fr_general_square_sport_adventure_music.png',
        style: 'general',
      },
      {
        ratio: 'landscape',
        locale: 'it',
        url: '/frames/christmas/33_it_general_landscape_sport_adventure_music.png',
        style: 'general',
      },
      {
        ratio: 'portrait',
        locale: 'it',
        url: '/frames/christmas/33_it_general_portrait_sport_adventure_music.png',
        style: 'general',
      },
      {
        ratio: 'square',
        locale: 'it',
        url: '/frames/christmas/33_it_general_square_sport_adventure_music.png',
        style: 'general',
      },
      {
        ratio: 'landscape',
        locale: 'pt',
        url: '/frames/christmas/33_pt_general_landscape_sport_adventure_music.png',
        style: 'general',
      },
      {
        ratio: 'portrait',
        locale: 'pt',
        url: '/frames/christmas/33_pt_general_portrait_sport_adventure_music.png',
        style: 'general',
      },
      {
        ratio: 'square',
        locale: 'pt',
        url: '/frames/christmas/33_pt_general_square_sport_adventure_music.png',
        style: 'general',
      },
    ],
    style: 'general',
    id: 33,
  },
  {
    categories: ['animals', 'adventure', 'sport'],
    images: [
      {
        ratio: 'landscape',
        locale: 'ca',
        url: '/frames/christmas/34_ca_fantasy_landscape_animals_adventure_sport.png',
        style: 'fantasy',
      },
      {
        ratio: 'portrait',
        locale: 'ca',
        url: '/frames/christmas/34_ca_fantasy_portrait_animals_adventure_sport.png',
        style: 'fantasy',
      },
      {
        ratio: 'square',
        locale: 'ca',
        url: '/frames/christmas/34_ca_fantasy_square_animals_adventure_sport.png',
        style: 'fantasy',
      },
      {
        ratio: 'landscape',
        locale: 'en',
        url: '/frames/christmas/34_en_fantasy_landscape_animals_adventure_sport.png',
        style: 'fantasy',
      },
      {
        ratio: 'portrait',
        locale: 'en',
        url: '/frames/christmas/34_en_fantasy_portrait_animals_adventure_sport.png',
        style: 'fantasy',
      },
      {
        ratio: 'square',
        locale: 'en',
        url: '/frames/christmas/34_en_fantasy_square_animals_adventure_sport.png',
        style: 'fantasy',
      },
      {
        ratio: 'landscape',
        locale: 'es',
        url: '/frames/christmas/34_es_fantasy_landscape_animals_adventure_sport.png',
        style: 'fantasy',
      },
      {
        ratio: 'portrait',
        locale: 'es',
        url: '/frames/christmas/34_es_fantasy_portrait_animals_adventure_sport.png',
        style: 'fantasy',
      },
      {
        ratio: 'square',
        locale: 'es',
        url: '/frames/christmas/34_es_fantasy_square_animals_adventure_sport.png',
        style: 'fantasy',
      },
      {
        ratio: 'landscape',
        locale: 'fr',
        url: '/frames/christmas/34_fr_fantasy_landscape_animals_adventure_sport.png',
        style: 'fantasy',
      },
      {
        ratio: 'portrait',
        locale: 'fr',
        url: '/frames/christmas/34_fr_fantasy_portrait_animals_adventure_sport.png',
        style: 'fantasy',
      },
      {
        ratio: 'square',
        locale: 'fr',
        url: '/frames/christmas/34_fr_fantasy_square_animals_adventure_sport.png',
        style: 'fantasy',
      },
      {
        ratio: 'landscape',
        locale: 'it',
        url: '/frames/christmas/34_it_fantasy_landscape_animals_adventure_sport.png',
        style: 'fantasy',
      },
      {
        ratio: 'portrait',
        locale: 'it',
        url: '/frames/christmas/34_it_fantasy_portrait_animals_adventure_sport.png',
        style: 'fantasy',
      },
      {
        ratio: 'square',
        locale: 'it',
        url: '/frames/christmas/34_it_fantasy_square_animals_adventure_sport.png',
        style: 'fantasy',
      },
      {
        ratio: 'landscape',
        locale: 'pt',
        url: '/frames/christmas/34_pt_fantasy_landscape_animals_adventure_sport.png',
        style: 'fantasy',
      },
      {
        ratio: 'portrait',
        locale: 'pt',
        url: '/frames/christmas/34_pt_fantasy_portrait_animals_adventure_sport.png',
        style: 'fantasy',
      },
      {
        ratio: 'square',
        locale: 'pt',
        url: '/frames/christmas/34_pt_fantasy_square_animals_adventure_sport.png',
        style: 'fantasy',
      },
    ],
    style: 'fantasy',
    id: 34,
  },
  {
    categories: ['animals', 'adventure', 'sport'],
    images: [
      {
        ratio: 'landscape',
        locale: 'ca',
        url: '/frames/christmas/35_ca_lego_landscape_animals_adventure_sport.png',
        style: 'lego',
      },
      {
        ratio: 'portrait',
        locale: 'ca',
        url: '/frames/christmas/35_ca_lego_portrait_animals_adventure_sport.png',
        style: 'lego',
      },
      {
        ratio: 'square',
        locale: 'ca',
        url: '/frames/christmas/35_ca_lego_square_animals_adventure_sport.png',
        style: 'lego',
      },
      {
        ratio: 'landscape',
        locale: 'en',
        url: '/frames/christmas/35_en_lego_landscape_animals_adventure_sport.png',
        style: 'lego',
      },
      {
        ratio: 'portrait',
        locale: 'en',
        url: '/frames/christmas/35_en_lego_portrait_animals_adventure_sport.png',
        style: 'lego',
      },
      {
        ratio: 'square',
        locale: 'en',
        url: '/frames/christmas/35_en_lego_square_animals_adventure_sport.png',
        style: 'lego',
      },
      {
        ratio: 'landscape',
        locale: 'es',
        url: '/frames/christmas/35_es_lego_landscape_animals_adventure_sport.png',
        style: 'lego',
      },
      {
        ratio: 'portrait',
        locale: 'es',
        url: '/frames/christmas/35_es_lego_portrait_animals_adventure_sport.png',
        style: 'lego',
      },
      {
        ratio: 'square',
        locale: 'es',
        url: '/frames/christmas/35_es_lego_square_animals_adventure_sport.png',
        style: 'lego',
      },
      {
        ratio: 'landscape',
        locale: 'fr',
        url: '/frames/christmas/35_fr_lego_landscape_animals_adventure_sport.png',
        style: 'lego',
      },
      {
        ratio: 'portrait',
        locale: 'fr',
        url: '/frames/christmas/35_fr_lego_portrait_animals_adventure_sport.png',
        style: 'lego',
      },
      {
        ratio: 'square',
        locale: 'fr',
        url: '/frames/christmas/35_fr_lego_square_animals_adventure_sport.png',
        style: 'lego',
      },
      {
        ratio: 'landscape',
        locale: 'it',
        url: '/frames/christmas/35_it_lego_landscape_animals_adventure_sport.png',
        style: 'lego',
      },
      {
        ratio: 'portrait',
        locale: 'it',
        url: '/frames/christmas/35_it_lego_portrait_animals_adventure_sport.png',
        style: 'lego',
      },
      {
        ratio: 'square',
        locale: 'it',
        url: '/frames/christmas/35_it_lego_square_animals_adventure_sport.png',
        style: 'lego',
      },
      {
        ratio: 'landscape',
        locale: 'pt',
        url: '/frames/christmas/35_pt_lego_landscape_animals_adventure_sport.png',
        style: 'lego',
      },
      {
        ratio: 'portrait',
        locale: 'pt',
        url: '/frames/christmas/35_pt_lego_portrait_animals_adventure_sport.png',
        style: 'lego',
      },
      {
        ratio: 'square',
        locale: 'pt',
        url: '/frames/christmas/35_pt_lego_square_animals_adventure_sport.png',
        style: 'lego',
      },
    ],
    style: 'lego',
    id: 35,
  },
  {
    categories: ['animals', 'adventure', 'sport'],
    images: [
      {
        ratio: 'landscape',
        locale: 'ca',
        url: '/frames/christmas/36_ca_general_landscape_animals_adventure_sport.png',
        style: 'general',
      },
      {
        ratio: 'portrait',
        locale: 'ca',
        url: '/frames/christmas/36_ca_general_portrait_animals_adventure_sport.png',
        style: 'general',
      },
      {
        ratio: 'square',
        locale: 'ca',
        url: '/frames/christmas/36_ca_general_square_animals_adventure_sport.png',
        style: 'general',
      },
      {
        ratio: 'landscape',
        locale: 'en',
        url: '/frames/christmas/36_en_general_landscape_animals_adventure_sport.png',
        style: 'general',
      },
      {
        ratio: 'portrait',
        locale: 'en',
        url: '/frames/christmas/36_en_general_portrait_animals_adventure_sport.png',
        style: 'general',
      },
      {
        ratio: 'square',
        locale: 'en',
        url: '/frames/christmas/36_en_general_square_animals_adventure_sport.png',
        style: 'general',
      },
      {
        ratio: 'landscape',
        locale: 'es',
        url: '/frames/christmas/36_es_general_landscape_animals_adventure_sport.png',
        style: 'general',
      },
      {
        ratio: 'portrait',
        locale: 'es',
        url: '/frames/christmas/36_es_general_portrait_animals_adventure_sport.png',
        style: 'general',
      },
      {
        ratio: 'square',
        locale: 'es',
        url: '/frames/christmas/36_es_general_square_animals_adventure_sport.png',
        style: 'general',
      },
      {
        ratio: 'landscape',
        locale: 'fr',
        url: '/frames/christmas/36_fr_general_landscape_animals_adventure_sport.png',
        style: 'general',
      },
      {
        ratio: 'portrait',
        locale: 'fr',
        url: '/frames/christmas/36_fr_general_portrait_animals_adventure_sport.png',
        style: 'general',
      },
      {
        ratio: 'square',
        locale: 'fr',
        url: '/frames/christmas/36_fr_general_square_animals_adventure_sport.png',
        style: 'general',
      },
      {
        ratio: 'landscape',
        locale: 'it',
        url: '/frames/christmas/36_it_general_landscape_animals_adventure_sport.png',
        style: 'general',
      },
      {
        ratio: 'portrait',
        locale: 'it',
        url: '/frames/christmas/36_it_general_portrait_animals_adventure_sport.png',
        style: 'general',
      },
      {
        ratio: 'square',
        locale: 'it',
        url: '/frames/christmas/36_it_general_square_animals_adventure_sport.png',
        style: 'general',
      },
      {
        ratio: 'landscape',
        locale: 'pt',
        url: '/frames/christmas/36_pt_general_landscape_animals_adventure_sport.png',
        style: 'general',
      },
      {
        ratio: 'portrait',
        locale: 'pt',
        url: '/frames/christmas/36_pt_general_portrait_animals_adventure_sport.png',
        style: 'general',
      },
      {
        ratio: 'square',
        locale: 'pt',
        url: '/frames/christmas/36_pt_general_square_animals_adventure_sport.png',
        style: 'general',
      },
    ],
    style: 'general',
    id: 36,
  },
  {
    categories: ['animals', 'sport', 'music'],
    images: [
      {
        ratio: 'landscape',
        locale: 'ca',
        url: '/frames/christmas/37_ca_fantasy_landscape_animals_sport_music.png',
        style: 'fantasy',
      },
      {
        ratio: 'portrait',
        locale: 'ca',
        url: '/frames/christmas/37_ca_fantasy_portrait_animals_sport_music.png',
        style: 'fantasy',
      },
      {
        ratio: 'square',
        locale: 'ca',
        url: '/frames/christmas/37_ca_fantasy_square_animals_sport_music.png',
        style: 'fantasy',
      },
      {
        ratio: 'landscape',
        locale: 'en',
        url: '/frames/christmas/37_en_fantasy_landscape_animals_sport_music.png',
        style: 'fantasy',
      },
      {
        ratio: 'portrait',
        locale: 'en',
        url: '/frames/christmas/37_en_fantasy_portrait_animals_sport_music.png',
        style: 'fantasy',
      },
      {
        ratio: 'square',
        locale: 'en',
        url: '/frames/christmas/37_en_fantasy_square_animals_sport_music.png',
        style: 'fantasy',
      },
      {
        ratio: 'landscape',
        locale: 'es',
        url: '/frames/christmas/37_es_fantasy_landscape_animals_sport_music.png',
        style: 'fantasy',
      },
      {
        ratio: 'portrait',
        locale: 'es',
        url: '/frames/christmas/37_es_fantasy_portrait_animals_sport_music.png',
        style: 'fantasy',
      },
      {
        ratio: 'square',
        locale: 'es',
        url: '/frames/christmas/37_es_fantasy_square_animals_sport_music.png',
        style: 'fantasy',
      },
      {
        ratio: 'landscape',
        locale: 'fr',
        url: '/frames/christmas/37_fr_fantasy_landscape_animals_sport_music.png',
        style: 'fantasy',
      },
      {
        ratio: 'portrait',
        locale: 'fr',
        url: '/frames/christmas/37_fr_fantasy_portrait_animals_sport_music.png',
        style: 'fantasy',
      },
      {
        ratio: 'square',
        locale: 'fr',
        url: '/frames/christmas/37_fr_fantasy_square_animals_sport_music.png',
        style: 'fantasy',
      },
      {
        ratio: 'landscape',
        locale: 'it',
        url: '/frames/christmas/37_it_fantasy_landscape_animals_sport_music.png',
        style: 'fantasy',
      },
      {
        ratio: 'portrait',
        locale: 'it',
        url: '/frames/christmas/37_it_fantasy_portrait_animals_sport_music.png',
        style: 'fantasy',
      },
      {
        ratio: 'square',
        locale: 'it',
        url: '/frames/christmas/37_it_fantasy_square_animals_sport_music.png',
        style: 'fantasy',
      },
      {
        ratio: 'landscape',
        locale: 'pt',
        url: '/frames/christmas/37_pt_fantasy_landscape_animals_sport_music.png',
        style: 'fantasy',
      },
      {
        ratio: 'portrait',
        locale: 'pt',
        url: '/frames/christmas/37_pt_fantasy_portrait_animals_sport_music.png',
        style: 'fantasy',
      },
      {
        ratio: 'square',
        locale: 'pt',
        url: '/frames/christmas/37_pt_fantasy_square_animals_sport_music.png',
        style: 'fantasy',
      },
    ],
    style: 'fantasy',
    id: 37,
  },
  {
    categories: ['animals', 'sport', 'music'],
    images: [
      {
        ratio: 'landscape',
        locale: 'ca',
        url: '/frames/christmas/38_ca_lego_landscape_animals_sport_music.png',
        style: 'lego',
      },
      {
        ratio: 'portrait',
        locale: 'ca',
        url: '/frames/christmas/38_ca_lego_portrait_animals_sport_music.png',
        style: 'lego',
      },
      {
        ratio: 'square',
        locale: 'ca',
        url: '/frames/christmas/38_ca_lego_square_animals_sport_music.png',
        style: 'lego',
      },
      {
        ratio: 'landscape',
        locale: 'en',
        url: '/frames/christmas/38_en_lego_landscape_animals_sport_music.png',
        style: 'lego',
      },
      {
        ratio: 'portrait',
        locale: 'en',
        url: '/frames/christmas/38_en_lego_portrait_animals_sport_music.png',
        style: 'lego',
      },
      {
        ratio: 'square',
        locale: 'en',
        url: '/frames/christmas/38_en_lego_square_animals_sport_music.png',
        style: 'lego',
      },
      {
        ratio: 'landscape',
        locale: 'es',
        url: '/frames/christmas/38_es_lego_landscape_animals_sport_music.png',
        style: 'lego',
      },
      {
        ratio: 'portrait',
        locale: 'es',
        url: '/frames/christmas/38_es_lego_portrait_animals_sport_music.png',
        style: 'lego',
      },
      {
        ratio: 'square',
        locale: 'es',
        url: '/frames/christmas/38_es_lego_square_animals_sport_music.png',
        style: 'lego',
      },
      {
        ratio: 'landscape',
        locale: 'fr',
        url: '/frames/christmas/38_fr_lego_landscape_animals_sport_music.png',
        style: 'lego',
      },
      {
        ratio: 'portrait',
        locale: 'fr',
        url: '/frames/christmas/38_fr_lego_portrait_animals_sport_music.png',
        style: 'lego',
      },
      {
        ratio: 'square',
        locale: 'fr',
        url: '/frames/christmas/38_fr_lego_square_animals_sport_music.png',
        style: 'lego',
      },
      {
        ratio: 'landscape',
        locale: 'it',
        url: '/frames/christmas/38_it_lego_landscape_animals_sport_music.png',
        style: 'lego',
      },
      {
        ratio: 'portrait',
        locale: 'it',
        url: '/frames/christmas/38_it_lego_portrait_animals_sport_music.png',
        style: 'lego',
      },
      {
        ratio: 'square',
        locale: 'it',
        url: '/frames/christmas/38_it_lego_square_animals_sport_music.png',
        style: 'lego',
      },
      {
        ratio: 'landscape',
        locale: 'pt',
        url: '/frames/christmas/38_pt_lego_landscape_animals_sport_music.png',
        style: 'lego',
      },
      {
        ratio: 'portrait',
        locale: 'pt',
        url: '/frames/christmas/38_pt_lego_portrait_animals_sport_music.png',
        style: 'lego',
      },
      {
        ratio: 'square',
        locale: 'pt',
        url: '/frames/christmas/38_pt_lego_square_animals_sport_music.png',
        style: 'lego',
      },
    ],
    style: 'lego',
    id: 38,
  },
  {
    categories: ['animals', 'sport', 'music'],
    images: [
      {
        ratio: 'landscape',
        locale: 'ca',
        url: '/frames/christmas/39_ca_general_landscape_animals_sport_music.png',
        style: 'general',
      },
      {
        ratio: 'portrait',
        locale: 'ca',
        url: '/frames/christmas/39_ca_general_portrait_animals_sport_music.png',
        style: 'general',
      },
      {
        ratio: 'square',
        locale: 'ca',
        url: '/frames/christmas/39_ca_general_square_animals_sport_music.png',
        style: 'general',
      },
      {
        ratio: 'landscape',
        locale: 'en',
        url: '/frames/christmas/39_en_general_landscape_animals_sport_music.png',
        style: 'general',
      },
      {
        ratio: 'portrait',
        locale: 'en',
        url: '/frames/christmas/39_en_general_portrait_animals_sport_music.png',
        style: 'general',
      },
      {
        ratio: 'square',
        locale: 'en',
        url: '/frames/christmas/39_en_general_square_animals_sport_music.png',
        style: 'general',
      },
      {
        ratio: 'landscape',
        locale: 'es',
        url: '/frames/christmas/39_es_general_landscape_animals_sport_music.png',
        style: 'general',
      },
      {
        ratio: 'portrait',
        locale: 'es',
        url: '/frames/christmas/39_es_general_portrait_animals_sport_music.png',
        style: 'general',
      },
      {
        ratio: 'square',
        locale: 'es',
        url: '/frames/christmas/39_es_general_square_animals_sport_music.png',
        style: 'general',
      },
      {
        ratio: 'landscape',
        locale: 'fr',
        url: '/frames/christmas/39_fr_general_landscape_animals_sport_music.png',
        style: 'general',
      },
      {
        ratio: 'portrait',
        locale: 'fr',
        url: '/frames/christmas/39_fr_general_portrait_animals_sport_music.png',
        style: 'general',
      },
      {
        ratio: 'square',
        locale: 'fr',
        url: '/frames/christmas/39_fr_general_square_animals_sport_music.png',
        style: 'general',
      },
      {
        ratio: 'landscape',
        locale: 'it',
        url: '/frames/christmas/39_it_general_landscape_animals_sport_music.png',
        style: 'general',
      },
      {
        ratio: 'portrait',
        locale: 'it',
        url: '/frames/christmas/39_it_general_portrait_animals_sport_music.png',
        style: 'general',
      },
      {
        ratio: 'square',
        locale: 'it',
        url: '/frames/christmas/39_it_general_square_animals_sport_music.png',
        style: 'general',
      },
      {
        ratio: 'landscape',
        locale: 'pt',
        url: '/frames/christmas/39_pt_general_landscape_animals_sport_music.png',
        style: 'general',
      },
      {
        ratio: 'portrait',
        locale: 'pt',
        url: '/frames/christmas/39_pt_general_portrait_animals_sport_music.png',
        style: 'general',
      },
      {
        ratio: 'square',
        locale: 'pt',
        url: '/frames/christmas/39_pt_general_square_animals_sport_music.png',
        style: 'general',
      },
    ],
    style: 'general',
    id: 39,
  },
  {
    categories: ['animals', 'adventure', 'music'],
    images: [
      {
        ratio: 'landscape',
        locale: 'ca',
        url: '/frames/christmas/40_ca_fantasy_landscape_animals_adventure_music.png',
        style: 'fantasy',
      },
      {
        ratio: 'portrait',
        locale: 'ca',
        url: '/frames/christmas/40_ca_fantasy_portrait_animals_adventure_music.png',
        style: 'fantasy',
      },
      {
        ratio: 'square',
        locale: 'ca',
        url: '/frames/christmas/40_ca_fantasy_square_animals_adventure_music.png',
        style: 'fantasy',
      },
      {
        ratio: 'landscape',
        locale: 'en',
        url: '/frames/christmas/40_en_fantasy_landscape_animals_adventure_music.png',
        style: 'fantasy',
      },
      {
        ratio: 'portrait',
        locale: 'en',
        url: '/frames/christmas/40_en_fantasy_portrait_animals_adventure_music.png',
        style: 'fantasy',
      },
      {
        ratio: 'square',
        locale: 'en',
        url: '/frames/christmas/40_en_fantasy_square_animals_adventure_music.png',
        style: 'fantasy',
      },
      {
        ratio: 'landscape',
        locale: 'es',
        url: '/frames/christmas/40_es_fantasy_landscape_animals_adventure_music.png',
        style: 'fantasy',
      },
      {
        ratio: 'portrait',
        locale: 'es',
        url: '/frames/christmas/40_es_fantasy_portrait_animals_adventure_music.png',
        style: 'fantasy',
      },
      {
        ratio: 'square',
        locale: 'es',
        url: '/frames/christmas/40_es_fantasy_square_animals_adventure_music.png',
        style: 'fantasy',
      },
      {
        ratio: 'landscape',
        locale: 'fr',
        url: '/frames/christmas/40_fr_fantasy_landscape_animals_adventure_music.png',
        style: 'fantasy',
      },
      {
        ratio: 'portrait',
        locale: 'fr',
        url: '/frames/christmas/40_fr_fantasy_portrait_animals_adventure_music.png',
        style: 'fantasy',
      },
      {
        ratio: 'square',
        locale: 'fr',
        url: '/frames/christmas/40_fr_fantasy_square_animals_adventure_music.png',
        style: 'fantasy',
      },
      {
        ratio: 'landscape',
        locale: 'it',
        url: '/frames/christmas/40_it_fantasy_landscape_animals_adventure_music.png',
        style: 'fantasy',
      },
      {
        ratio: 'portrait',
        locale: 'it',
        url: '/frames/christmas/40_it_fantasy_portrait_animals_adventure_music.png',
        style: 'fantasy',
      },
      {
        ratio: 'square',
        locale: 'it',
        url: '/frames/christmas/40_it_fantasy_square_animals_adventure_music.png',
        style: 'fantasy',
      },
      {
        ratio: 'landscape',
        locale: 'pt',
        url: '/frames/christmas/40_pt_fantasy_landscape_animals_adventure_music.png',
        style: 'fantasy',
      },
      {
        ratio: 'portrait',
        locale: 'pt',
        url: '/frames/christmas/40_pt_fantasy_portrait_animals_adventure_music.png',
        style: 'fantasy',
      },
      {
        ratio: 'square',
        locale: 'pt',
        url: '/frames/christmas/40_pt_fantasy_square_animals_adventure_music.png',
        style: 'fantasy',
      },
    ],
    style: 'fantasy',
    id: 40,
  },
  {
    categories: ['animals', 'adventure', 'music'],
    images: [
      {
        ratio: 'landscape',
        locale: 'ca',
        url: '/frames/christmas/41_ca_lego_landscape_animals_adventure_music.png',
        style: 'lego',
      },
      {
        ratio: 'portrait',
        locale: 'ca',
        url: '/frames/christmas/41_ca_lego_portrait_animals_adventure_music.png',
        style: 'lego',
      },
      {
        ratio: 'square',
        locale: 'ca',
        url: '/frames/christmas/41_ca_lego_square_animals_adventure_music.png',
        style: 'lego',
      },
      {
        ratio: 'landscape',
        locale: 'en',
        url: '/frames/christmas/41_en_lego_landscape_animals_adventure_music.png',
        style: 'lego',
      },
      {
        ratio: 'portrait',
        locale: 'en',
        url: '/frames/christmas/41_en_lego_portrait_animals_adventure_music.png',
        style: 'lego',
      },
      {
        ratio: 'square',
        locale: 'en',
        url: '/frames/christmas/41_en_lego_square_animals_adventure_music.png',
        style: 'lego',
      },
      {
        ratio: 'landscape',
        locale: 'es',
        url: '/frames/christmas/41_es_lego_landscape_animals_adventure_music.png',
        style: 'lego',
      },
      {
        ratio: 'portrait',
        locale: 'es',
        url: '/frames/christmas/41_es_lego_portrait_animals_adventure_music.png',
        style: 'lego',
      },
      {
        ratio: 'square',
        locale: 'es',
        url: '/frames/christmas/41_es_lego_square_animals_adventure_music.png',
        style: 'lego',
      },
      {
        ratio: 'landscape',
        locale: 'fr',
        url: '/frames/christmas/41_fr_lego_landscape_animals_adventure_music.png',
        style: 'lego',
      },
      {
        ratio: 'portrait',
        locale: 'fr',
        url: '/frames/christmas/41_fr_lego_portrait_animals_adventure_music.png',
        style: 'lego',
      },
      {
        ratio: 'square',
        locale: 'fr',
        url: '/frames/christmas/41_fr_lego_square_animals_adventure_music.png',
        style: 'lego',
      },
      {
        ratio: 'landscape',
        locale: 'it',
        url: '/frames/christmas/41_it_lego_landscape_animals_adventure_music.png',
        style: 'lego',
      },
      {
        ratio: 'portrait',
        locale: 'it',
        url: '/frames/christmas/41_it_lego_portrait_animals_adventure_music.png',
        style: 'lego',
      },
      {
        ratio: 'square',
        locale: 'it',
        url: '/frames/christmas/41_it_lego_square_animals_adventure_music.png',
        style: 'lego',
      },
      {
        ratio: 'landscape',
        locale: 'pt',
        url: '/frames/christmas/41_pt_lego_landscape_animals_adventure_music.png',
        style: 'lego',
      },
      {
        ratio: 'portrait',
        locale: 'pt',
        url: '/frames/christmas/41_pt_lego_portrait_animals_adventure_music.png',
        style: 'lego',
      },
      {
        ratio: 'square',
        locale: 'pt',
        url: '/frames/christmas/41_pt_lego_square_animals_adventure_music.png',
        style: 'lego',
      },
    ],
    style: 'lego',
    id: 41,
  },
  {
    categories: ['animals', 'adventure', 'music'],
    images: [
      {
        ratio: 'landscape',
        locale: 'ca',
        url: '/frames/christmas/42_ca_general_landscape_animals_adventure_music.png',
        style: 'general',
      },
      {
        ratio: 'portrait',
        locale: 'ca',
        url: '/frames/christmas/42_ca_general_portrait_animals_adventure_music.png',
        style: 'general',
      },
      {
        ratio: 'square',
        locale: 'ca',
        url: '/frames/christmas/42_ca_general_square_animals_adventure_music.png',
        style: 'general',
      },
      {
        ratio: 'landscape',
        locale: 'en',
        url: '/frames/christmas/42_en_general_landscape_animals_adventure_music.png',
        style: 'general',
      },
      {
        ratio: 'portrait',
        locale: 'en',
        url: '/frames/christmas/42_en_general_portrait_animals_adventure_music.png',
        style: 'general',
      },
      {
        ratio: 'square',
        locale: 'en',
        url: '/frames/christmas/42_en_general_square_animals_adventure_music.png',
        style: 'general',
      },
      {
        ratio: 'landscape',
        locale: 'es',
        url: '/frames/christmas/42_es_general_landscape_animals_adventure_music.png',
        style: 'general',
      },
      {
        ratio: 'portrait',
        locale: 'es',
        url: '/frames/christmas/42_es_general_portrait_animals_adventure_music.png',
        style: 'general',
      },
      {
        ratio: 'square',
        locale: 'es',
        url: '/frames/christmas/42_es_general_square_animals_adventure_music.png',
        style: 'general',
      },
      {
        ratio: 'landscape',
        locale: 'fr',
        url: '/frames/christmas/42_fr_general_landscape_animals_adventure_music.png',
        style: 'general',
      },
      {
        ratio: 'portrait',
        locale: 'fr',
        url: '/frames/christmas/42_fr_general_portrait_animals_adventure_music.png',
        style: 'general',
      },
      {
        ratio: 'square',
        locale: 'fr',
        url: '/frames/christmas/42_fr_general_square_animals_adventure_music.png',
        style: 'general',
      },
      {
        ratio: 'landscape',
        locale: 'it',
        url: '/frames/christmas/42_it_general_landscape_animals_adventure_music.png',
        style: 'general',
      },
      {
        ratio: 'portrait',
        locale: 'it',
        url: '/frames/christmas/42_it_general_portrait_animals_adventure_music.png',
        style: 'general',
      },
      {
        ratio: 'square',
        locale: 'it',
        url: '/frames/christmas/42_it_general_square_animals_adventure_music.png',
        style: 'general',
      },
      {
        ratio: 'landscape',
        locale: 'pt',
        url: '/frames/christmas/42_pt_general_landscape_animals_adventure_music.png',
        style: 'general',
      },
      {
        ratio: 'portrait',
        locale: 'pt',
        url: '/frames/christmas/42_pt_general_portrait_animals_adventure_music.png',
        style: 'general',
      },
      {
        ratio: 'square',
        locale: 'pt',
        url: '/frames/christmas/42_pt_general_square_animals_adventure_music.png',
        style: 'general',
      },
    ],
    style: 'general',
    id: 42,
  },
];
