import { createAction } from '@reduxjs/toolkit';
import { FrameStyle } from '../types';

function onSelectStyle(style: FrameStyle) {
  return {
    payload: style,
  };
}

export const selectStyle = createAction('style/select-style', onSelectStyle);
