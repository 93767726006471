import React from 'react';
import styled from 'styled-components';
import Link from 'next/link';
import LogoIcon from '../assets/icons/logo.svg';
import { FormattedMessage } from 'react-intl';
import FacebookIcon from '../assets/icons/icon_facebook.svg';
import TwitterIcon from '../assets/icons/icon_twitter.svg';
import InstagramIcon from '../assets/icons/icon_instagram.svg';
import YoutubeIcon from '../assets/icons/icon_youtube.svg';
import { LanguageSelector } from './language-selector';
import usePrivacyAndCookiePolicyUrl from '../hooks/use-privacy-and-cookie-policy-url';

const Container = styled.footer`
  background-color: ${ ({theme}) => theme.colors.mediumViolet };
  padding: 36px 12px 55px;
  overflow: hidden;
`;

const Row = styled.div`
  display: flex;
`;

const LanguageContainer = styled.div`
  margin-left: 24px;
`;

const LogoContainer = styled.div`
  width: 68px;
  height: 68px;
`;

const Logo = styled(LogoIcon)`
  cursor: pointer;
`;

const Follow = styled.div`
  margin: 48px 0 0;
`;

const Title = styled.h4`
  font-family: ${ ({theme}) => theme.fonts.cera };
  color: ${ ({theme}) => theme.colors.allWhite };
  font-size: 16px;
  line-height: 1.2;
  font-weight: 400;
  margin: 0;
  text-transform: uppercase;
`;

const SocialIcons = styled.div`
  display: flex;
  margin-top: 24px;

  a {
    margin-right: 18px;

    &:last-child {
      margin: 0;
    }
  }
`;

const Links = styled.div`
  margin: 36px 0;
`;

const LinkRow = styled.div`
  margin-bottom: 24px;

  &:last-child {
    margin: 0;
  }

  a {
    display: inline-block;
    font-family: ${ ({theme}) => theme.fonts.cera };
    color: ${ ({theme}) => theme.colors.allWhite };
    font-size: 12px;
    line-height: 1.2;
    font-weight: 400;
    margin: 0 32px 0 0;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
`;

const Copyright = styled.p`
  font-family: ${ ({theme}) => theme.fonts.cera };
  color: ${ ({theme}) => theme.colors.allWhite };
  font-size: 12px;
  line-height: 1.6;
  font-weight: 400;
  margin: 0;
`;

export function Footer({ longLocale }: { longLocale: 'en-us' | 'it-it' | 'fr-fr' | 'es-es' | 'pt-pt' }) {
  const [privacyPolicyUrl, cookiePolicyUrl] = usePrivacyAndCookiePolicyUrl();
  return (
    <Container>
      <Row>
        <Link href="/" passHref>
          <LogoContainer>
            <Logo width="68px" height="68px" />
          </LogoContainer>
        </Link>
        <LanguageContainer>
          <LanguageSelector />
        </LanguageContainer>
      </Row>
      <Follow>
        <Title>
          <FormattedMessage
            defaultMessage="Follow us"
            id="follow_us"
          />
        </Title>
        <SocialIcons>
          <a href="https://www.facebook.com/LEGO" target="_blank" rel="noreferrer">
            <FacebookIcon />
          </a>
          <a href="https://twitter.com/LEGO_Group" target="_blank" rel="noreferrer">
            <TwitterIcon />
          </a>
          <a href="https://www.instagram.com/lego" target="_blank" rel="noreferrer">
            <InstagramIcon />
          </a>
          <a href="https://www.youtube.com/user/LEGO" target="_blank" rel="noreferrer">
            <YoutubeIcon />
          </a>
        </SocialIcons>
      </Follow>

      <Links>
        <LinkRow>
          <a href={privacyPolicyUrl} target="_blank" rel="noreferrer">
            <FormattedMessage
              defaultMessage="Privacy Policy"
              id="privacy_policy"
            />
          </a>
          <a href={`https://www.lego.com/${longLocale}/legal/notices-and-policies/legal-notice`} target="_blank" rel="noreferrer">
            <FormattedMessage
              defaultMessage="Legal notice"
              id="legal_notice"
            />
          </a>
          <a href={`https://www.lego.com/${longLocale}/legal/notices-and-policies/legal-notice`} target="_blank" rel="noreferrer">
            <FormattedMessage
              defaultMessage="Terms of Use"
              id="terms_of_use"
            />
          </a>
        </LinkRow>
        <LinkRow>
          <a href={`https://www.lego.com/${longLocale}/page/accessibility`} target="_blank" rel="noreferrer">
            <FormattedMessage
              defaultMessage="Accessibility"
              id="accessibility"
            />
          </a>
          <a href={cookiePolicyUrl} target="_blank" rel="noreferrer">
            <FormattedMessage
              defaultMessage="Cookies Policy"
              id="cookies_policy"
            />
          </a>
        </LinkRow>
      </Links>

      <Copyright>
        <FormattedMessage
          defaultMessage="LEGO System A/S, DK-7190 Billund, Denmark. Must be 18 years or older to purchase online. LEGO, the LEGO logo, the Minifigure, DUPLO, LEGENDS OF CHIMA, NINJAGO, BIONICLE, MINDSTORMS and MIXELS are trademarks and copyrights of the LEGO Group. ©2023 The LEGO Group. All rights reserved. Use of this site signifies your agreement to the Terms of Use."
          id="footer_copyright"
        />
      </Copyright>
    </Container>
  );
}
