import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { selectAnimals } from '../actions/select-animals';
import { selectAdventure } from '../actions/select-adventure';
import { selectSport } from '../actions/select-sport';
import { selectMusic } from '../actions/select-music';
import { setCurrentStep } from '../actions/set-current-step';
import { resetCard } from '../actions/reset-card';

export type PassionsType = {
  animals: boolean | null;
  adventure: boolean | null;
  sport: boolean | null;
  music: boolean | null;
  currentStep: 0 | 1 | 2 | 3;
}

const initialState: PassionsType = {
  animals: false,
  adventure: false,
  sport: false,
  music: false,
  currentStep: 0,
};

function onSelectAnimals(state: PassionsType, { payload }: PayloadAction<boolean>): void {
  state.animals = payload;
  state.currentStep = 1;
}

function onSelectAdventure(state: PassionsType, { payload }: PayloadAction<boolean>): void {
  state.adventure = payload;
  state.currentStep = 2;
}

function onSelectSport(state: PassionsType, { payload }: PayloadAction<boolean>): void {
  state.sport = payload;
  state.currentStep = 3;
}

function onSelectMusic(state: PassionsType, { payload }: PayloadAction<boolean>): void {
  state.music = payload;
}

function onSetCurrentStep(state: PassionsType, { payload }: PayloadAction<PassionsType['currentStep']>): void {
  state.currentStep = payload;
}

function onResetPassions(state: PassionsType): void {
  state.animals = initialState.animals;
  state.adventure = initialState.adventure;
  state.sport = initialState.sport;
  state.music = initialState.music;
  state.currentStep = initialState.currentStep;
}

export const passionsSlice = createSlice({
  name: 'passions',
  initialState,
  reducers: {},
  extraReducers: {
    [selectAnimals as unknown as string]: onSelectAnimals,
    [selectAdventure as unknown as string]: onSelectAdventure,
    [selectSport as unknown as string]: onSelectSport,
    [selectMusic as unknown as string]: onSelectMusic,
    [resetCard as unknown as string]: onResetPassions,
    [setCurrentStep as unknown as string]: onSetCurrentStep,
  },
});
