import { useRouter } from 'next/router';
import { useCallback, useEffect, useState } from 'react';
import { Loading } from '../components/loading';

export function useLoading() {
  const router = useRouter();
  const [isLoading, setLoading] = useState(true);
  const handleStart = useCallback(() => setLoading(true), []);
  const handleComplete = useCallback(() => setTimeout(() => setLoading(false), 1000), []);

  useEffect(() => {
    router.events.on('routeChangeStart', handleStart);
    router.events.on('routeChangeComplete', handleComplete);
    router.events.on('routeChangeError', handleComplete);

    return () => {
      router.events.off('routeChangeStart', handleStart);
      router.events.off('routeChangeComplete', handleComplete);
      router.events.off('routeChangeError', handleComplete);
    };
  });

  useEffect(() => {
    handleComplete();
  }, [handleComplete]);

  if (isLoading) return <Loading />;
  return (<></>);
}
