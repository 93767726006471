import '../styles/globals.css';
import React, { useEffect, useMemo } from 'react';
import { Provider } from 'react-redux';
import Head from 'next/head';
import AppThemeProvider from '../components/app-theme-provider';
import type { AppProps } from 'next/app';
import { useRouter } from 'next/router';
import { IntlProvider } from 'react-intl';
import English from '../compiled-locales/en.json';
import Italian from '../compiled-locales/it.json';
import French from '../compiled-locales/fr.json';
import Spanish from '../compiled-locales/es.json';
import Catalan from '../compiled-locales/ca.json';
import Portuguese from '../compiled-locales/pt.json';
import store from '../store';
import { Navbar } from '../components/navbar';
import { Footer } from '../components/footer';
import { setLocale } from '../store/actions/set-locale';
import { Locale } from '../store/types';
import { useLoading as Loading } from '../hooks/use-loading';
import useSendRouteChangeToGa from '../hooks/use-send-route-change-to-ga';
import useCookiePolicyIdAndLanguage from '../hooks/use-cookie-policy-id-and-language';

function App({Component, pageProps}: AppProps) {
  const {locale} = useRouter();
  const [shortLocale] = locale ? locale.split('-') : ['en'];
  useSendRouteChangeToGa();

  const longLocale = useMemo(() => {
    switch (shortLocale) {
      case 'it':
        return 'it-it';
      case 'fr':
        return 'fr-fr';
      case 'es':
      case 'ca':
        return 'es-es';
      case 'pt':
        return 'pt-pt';
      case 'en':
      default:
        return 'en-us';
    }
  }, [shortLocale]);

  const [cookiePolicyId, cookiePolicyLocale] = useCookiePolicyIdAndLanguage();

  const messages = useMemo(() => {
    switch (shortLocale) {
      case 'it':
        return Italian;
      case 'fr':
        return French;
      case 'es':
        return Spanish;
      case 'ca':
        return Catalan;
      case 'pt':
        return Portuguese;
      case 'en':
      default:
        return English;
    }
  }, [shortLocale]);

  useEffect(() => {
    store.dispatch(
      setLocale(shortLocale as unknown as Locale),
    );
  }, [shortLocale]);

  return (
    <>
    <Head>
      <title>Digital Card | LEGO®</title>
      <script
        dangerouslySetInnerHTML={{
          __html: `
            var _iub = _iub || [];
            _iub.csConfiguration = { "consentOnContinuedBrowsing":false,"lang":"${cookiePolicyLocale}","floatingPreferencesButtonDisplay":"bottom-right","siteId":2406623,"cookiePolicyId":${cookiePolicyId}, "banner":{ "acceptButtonDisplay":true,"customizeButtonDisplay":true,"position":"float-top-center","acceptButtonColor":"#26265e","acceptButtonCaptionColor":"white","customizeButtonColor":"#DADADA","customizeButtonCaptionColor":"#4D4D4D","rejectButtonColor":"#4c2f92","rejectButtonCaptionColor":"white","textColor":"black","backgroundColor":"#f6f6f6","rejectButtonDisplay":true,"brandBackgroundColor":"none","brandTextColor":"black" } };
            `,
        }}
      />
      <script
        type="text/javascript"
        charSet="UTF-8"
        src="//cdn.iubenda.com/cs/iubenda_cs.js"
        async
      />
    </Head>
    <Provider store={ store }>
      <AppThemeProvider>
        <IntlProvider
          locale={ shortLocale }
          messages={ messages }
          onError={ () => null }
        >
          <Navbar />
          <Loading />
          <Component { ...pageProps } />
          <Footer longLocale={longLocale} />
        </IntlProvider>
      </AppThemeProvider>
    </Provider>
    </>
  );
}

export default App;
