export const PUBLIC_GOOGLE_ANALYTICS = 'G-783183RKPB';

type WindowWithGA = Window & {
  dataLayer: Array<any>;
  gtag: (ev: 'event', action: string, opts: any) => void;
};

export const pageview = (url: string) => {
  event({
    action: 'page_view',
    params: {
      page_path: url,
    }
  });
};

export const event = ({ action, params }: { action: string; params?: any; }) => {
  (window as unknown as WindowWithGA).gtag('event', action, params);
}
