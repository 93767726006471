import { createAction } from '@reduxjs/toolkit';
import { FrameImageType } from '../types';

function onSelectFrame(frame: FrameImageType & { id: number; }) {
  return {
    payload: frame,
  };
}

export const selectFrame = createAction('card/select-frame', onSelectFrame);
