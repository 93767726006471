import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { chain, find, first, isUndefined } from 'lodash';
import { FrameImageType, FrameStyle, Locale } from '../types';
import { setLocale } from '../actions/set-locale';
import { selectAnimals } from '../actions/select-animals';
import { selectAdventure } from '../actions/select-adventure';
import { selectSport } from '../actions/select-sport';
import { selectMusic } from '../actions/select-music';
import { selectStyle } from '../actions/select-style';
import { CardFrames } from '../../constants/card-frames';
import { selectRatio } from '../actions/select-ratio';
import { selectZoom } from '../actions/select-zoom';
import { selectFrame } from '../actions/select-frame';
import { setName } from '../actions/set-name';
import { setUserPhoto } from '../actions/set-user-photo';
import { resetCard } from '../actions/reset-card';
import { ImagePosition, selectImagePosition } from '../actions/select-image-position';

type InternalFrameImageType = FrameImageType & {
  id: number;
  categories: Array<'animals' | 'adventure' | 'sport' | 'music'>;
  style: FrameStyle;
};

const InternalCardFrames: Array<InternalFrameImageType> = chain(CardFrames)
  .reduce<Array<InternalFrameImageType>>(
    (acc, { categories, style, id, images }) => {
      acc.push(
        ...chain<FrameImageType>(images)
          .map<InternalFrameImageType>((obj) => ({
            ...obj,
            categories,
            style,
            id
          }))
          .value()
      );
      return acc;
    },
    [],
  )
  .value();

export type FrameState = {
  locale: Locale;
  ratio: FrameImageType['ratio'];
  zoom: number;
  imagePosition: {
    x: number;
    y: number;
  };
  style: FrameStyle | '';
  name: string;
  userPhoto?: {
    data: string;
    width: number;
    height: number;
  };
  availableFrames: Array<FrameImageType & { id: number; }>;
  currentFrame?: FrameImageType & { id: number; };
  adventure: boolean;
  animals: boolean;
  music: boolean;
  sport: boolean;
}

const initialAvailableFrames = getAvailableFrames({
  locale: 'en',
  ratio: 'square',
  zoom: 1,
  imagePosition: {
    x: 0,
    y: 0,
  },
  style: '',
  name: '',
  availableFrames: [],
  adventure: false,
  animals: false,
  music: false,
  sport: false,
});

const initialState: FrameState = {
  locale: 'en',
  ratio: 'square',
  zoom: 1,
  imagePosition: {
    x: 0,
    y: 0,
  },
  style: '',
  name: '',
  availableFrames: initialAvailableFrames,
  currentFrame: first(initialAvailableFrames),
  adventure: false,
  animals: false,
  music: false,
  sport: false,
};

function getAvailableFrames({
                              locale,
                              ratio,
                              style,
                              adventure,
                              animals,
                              music,
                              sport,
                            }: FrameState): Array<FrameImageType & { id: number; }> {
  return chain<InternalFrameImageType>(InternalCardFrames)
    .filter(['style', (style === '' ? 'general' : style)]) // Filtra i frame secondo lo stile
    .filter(['locale', locale]) // Filtra per locale
    .filter(['ratio', ratio]) // Filtra per ratio
    .filter(({ categories }: InternalFrameImageType) => { // Filtra i frame secondo le categorie
      if (!adventure && !animals && !music && !sport) {
        return true;
      }
      let show = false;
      if (adventure) show = show || !isUndefined(find(categories, (cat) => cat === 'adventure'));
      if (animals) show =  show|| !isUndefined(find(categories, (cat) => cat === 'animals'));
      if (music) show = show || !isUndefined(find(categories, (cat) => cat === 'music'));
      if (sport) show = show || !isUndefined(find(categories, (cat) => cat === 'sport'));
      return show;
    })
    .map(({ id, ratio, locale, url, style }: InternalFrameImageType) => ({
      id,
      ratio,
      locale,
      url,
      style,
    }))
    .value();
}

function updateFrames(state: FrameState, keepSameId = false): void {
  state.availableFrames = getAvailableFrames(state);
  if (state.currentFrame && keepSameId) {
    state.currentFrame = find(state.availableFrames, { id: state.currentFrame.id });
  }
  if (isUndefined(state.currentFrame)
    || isUndefined(find(state.availableFrames, { id: state.currentFrame.id }))) {
    state.currentFrame = first(state.availableFrames);
  }
}

function onSetLocale(state: FrameState, { payload }: PayloadAction<Locale>): void {
  state.locale = payload;
  updateFrames(state, true);
}

function onSelectRatio(state: FrameState, { payload }: PayloadAction<FrameImageType['ratio']>): void {
  state.ratio = payload;
  state.imagePosition = {
    x: 0,
    y: 0,
  };
  updateFrames(state, true);
}

function onSelectZoom(state: FrameState, { payload }: PayloadAction<number>): void {
  state.zoom = payload;
  updateFrames(state, true);
}

function onSelectImagePosition(state: FrameState, { payload }: PayloadAction<ImagePosition>): void {
  state.imagePosition = payload;
  updateFrames(state, true);
}

function onSelectStyle(state: FrameState, { payload }: PayloadAction<FrameStyle>): void {
  state.style = payload;
  updateFrames(state);
}

function onSelectFrame(state: FrameState, { payload }: PayloadAction<FrameImageType & { id: number; }>): void {
  state.currentFrame = payload;
}

function onSelectAdventure(state: FrameState, { payload }: PayloadAction<boolean>): void {
  state.adventure = payload;
  updateFrames(state);
}

function onSelectAnimals(state: FrameState, { payload }: PayloadAction<boolean>): void {
  state.animals = payload;
  updateFrames(state);
}

function onSelectMusic(state: FrameState, { payload }: PayloadAction<boolean>): void {
  state.music = payload;
  updateFrames(state);
}

function onSelectSport(state: FrameState, { payload }: PayloadAction<boolean>): void {
  state.sport = payload;
  updateFrames(state);
}

function onSetName(state: FrameState, { payload }: PayloadAction<string>): void {
  state.name = payload;
}

function onSetUserPhoto(state: FrameState, { payload }: PayloadAction<FrameState['userPhoto']>): void {
  state.userPhoto = payload;
}

function onResetCard(state: FrameState): void {
  state.locale = initialState.locale;
  state.ratio = initialState.ratio;
  state.style = initialState.style;
  state.name = initialState.name;
  state.availableFrames = initialState.availableFrames;
  state.currentFrame = undefined;
  state.userPhoto = undefined;
  state.adventure = initialState.adventure;
  state.animals = initialState.animals;
  state.music = initialState.music;
  state.sport = initialState.sport;
  state.imagePosition = initialState.imagePosition;
}

export const frameSlice = createSlice({
  name: 'frame',
  initialState,
  reducers: {},
  extraReducers: {
    [setLocale as unknown as string]: onSetLocale,
    [selectRatio as unknown as string]: onSelectRatio,
    [selectZoom as unknown as string]: onSelectZoom,
    [selectImagePosition as unknown as string]: onSelectImagePosition,
    [selectStyle as unknown as string]: onSelectStyle,
    [selectFrame as unknown as string]: onSelectFrame,
    [setName as unknown as string]: onSetName,
    [setUserPhoto as unknown as string]: onSetUserPhoto,
    [selectAdventure as unknown as string]: onSelectAdventure,
    [selectAnimals as unknown as string]: onSelectAnimals,
    [selectMusic as unknown as string]: onSelectMusic,
    [selectSport as unknown as string]: onSelectSport,
    [resetCard as unknown as string]: onResetCard,
  },
});
