'use client';

import React, { PropsWithChildren, ReactElement, useMemo, useState } from 'react';
import AppThemeContext, { AppTheme, AppThemeCtxt, AppThemeEnhancements } from '../contexts/app-theme-context';
import { ThemeProvider } from 'styled-components';

export type ColorsType =
  'allWhite' |
  'legoWhite' |
  'black' |
  'brightYellow' |
  'brightRed' |
  'brightBlue' |
  'cinnabar' |
  'chiliRed' |
  'vibrantCoral' |
  'mediumViolet' |
  'darkViolet' |
  'xmasOrange' |
  'xmasPurple' |
  'xmasGreen' |
  'xmasPink' |
  'xmasAqua' |
  'lightRoyalBlue' |
  'darkAzur' |
  'seaFoam' |
  'darkGreen' |
  'fogGray' |
  'darkMineGray' |
  'darkStoneGray';

export const defaultTheme: AppTheme = {
  colors: {
    allWhite: '#FFF',
    legoWhite: '#F6F6F6',
    black: '#000',
    brightYellow: '#FFD400',
    brightRed: '#DD1A22',
    brightBlue: '#006CB7',
    cinnabar: '#E6504D',
    chiliRed: '#BB2026',
    vibrantCoral: '#F27173',
    mediumViolet: '#26265E',
    darkViolet: '#1F1846',
    newViolet: '#4C2F92',
    xmasOrange: '#EF7700',
    xmasPurple: '#A776B2',
    xmasGreen: '#A3D94B',
    xmasPink: '#E46BAD',
    xmasAqua: '#33A1A6',
    orangeOrange: '#FFA620',
    lightRoyalBlue: '#78BFEA',
    darkAzur: '#00A3DA',
    skyBlue: '#56A3D9',
    seaFoam: '#8ECDA4',
    darkGreen: '#009247',
    brightGreen: '#00AF4D',
    fogGray: '#E0E0E0',
    darkMineGray: '#282828',
    darkStoneGray: '#646464'
  },
  fonts: {
    cera: 'Cera Pro, \'sans-serif\'',
  },
};

export default function AppThemeProvider({children}: PropsWithChildren<any>): ReactElement {
  const [enhancements, setThemeEnhancements] = useState<AppThemeEnhancements>(defaultTheme);
  const ctxt = useMemo<AppThemeCtxt>(() => {
    return ({
      setTheme: setThemeEnhancements
    });
  }, [setThemeEnhancements]);

  const theme = useMemo<AppTheme>(() => ({
    ...enhancements,
    ...defaultTheme,
  }), [enhancements]);

  return (
    <AppThemeContext.Provider value={ ctxt }>
      <ThemeProvider theme={ theme }>
        { children }
      </ThemeProvider>
    </AppThemeContext.Provider>
  );
}
