import { createContext } from 'react';

export type AppTheme = {
  colors: {
    [name: string]: string;
  };
  fonts: {
    [name: string]: string;
  };
};

export type AppThemeEnhancements = Partial<AppTheme>;

export type SetThemeFn = (enhancements: AppThemeEnhancements) => void;

export type AppThemeCtxt = {
  setTheme: SetThemeFn;
};

const AppThemeContext = createContext<AppThemeCtxt>({
  setTheme: () => undefined,
});

export default AppThemeContext;
