import React from 'react';
import styled from 'styled-components';
import Link from 'next/link';
import LogoIcon from '../assets/icons/logo.svg';

const Container = styled.header``;

const NavbarContainer = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  height: 56px;
  display: flex;
  align-items: center;
  background-color: ${ ({theme}) => theme.colors.allWhite };
  padding: 0 12px;
  z-index: 3;
`;

const LogoContainer = styled.div`
  width: 40px;
  height: 40px;
`;

const Logo = styled(LogoIcon)`
  cursor: pointer;
`;

const Line = styled.span`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 2px;
  pointer-events: none;
  background-color: ${ ({theme}) => theme.colors.brightYellow };
`;

const Space = styled.div`
  height: 56px;
  visibility: hidden;
`;

export function Navbar() {
  return (
    <Container>
      <NavbarContainer>
        <Link href="/" passHref>
          <LogoContainer>
            <Logo width="40px" height="40px" />
          </LogoContainer>
        </Link>
        <Line />
      </NavbarContainer>
      <Space />
    </Container>
  );
}
