import React, { useMemo } from 'react';
import styled from 'styled-components';
import { rgba } from 'polished';
import EnFlag from '../assets/icons/icon_en_flag.svg';
import ItFlag from '../assets/icons/icon_it_flag.svg';
import FrFlag from '../assets/icons/icon_fr_flag.svg';
import EsFlag from '../assets/icons/icon_es_flag.svg';
import PtFlag from '../assets/icons/icon_pt_flag.svg';
import { useIntl } from 'react-intl';

const Container = styled.div`
  width: 232px;
  padding: 12px;
  display: flex;
  align-items: center;
  background-color: ${ ({theme}) => theme.colors.darkViolet };
  cursor: pointer;

  &:hover {
    background-color: ${ ({theme}) => rgba(theme.colors.darkViolet, 0.9) };
  }
`;

const Text = styled.h3`
  font-family: ${ ({theme}) => theme.fonts.cera };
  color: ${ ({theme}) => theme.colors.allWhite };
  font-size: 18px;
  line-height: 1.2;
  font-weight: 400;
  margin: 0 0 0 12px;
  pointer-events: none;
`;

export type LanguageType = {
  locale: 'en' | 'it' | 'fr' | 'es' | 'ca' | 'pt';
  onClick?: (e: any) => void;
}

export function Language({ locale, onClick }: LanguageType) {
  const intl = useIntl();
  const currentLanguage = useMemo(() => {
    switch (locale) {
      case 'it':
        return {
          flag: <ItFlag />,
          text: intl.formatMessage({
            id: 'switch_language_it',
            defaultMessage: 'Cambia lingua',
          })
        };
      case 'fr':
        return {
          flag: <FrFlag />,
          text: intl.formatMessage({
            id: 'switch_language_fr',
            defaultMessage: 'Changer de langue',
          })
        };
      case 'es':
        return {
          flag: <EsFlag />,
          text: intl.formatMessage({
            id: 'switch_language_es',
            defaultMessage: 'Cambiar el idioma',
          })
        };
      case 'ca':
        return {
          flag: <EsFlag />,
          text: intl.formatMessage({
            id: 'switch_language_ca',
            defaultMessage: 'Canviar d\'idioma',
          })
        };
      case 'pt':
        return {
          flag: <PtFlag />,
          text: intl.formatMessage({
            id: 'switch_language_pt',
            defaultMessage: 'Mudar idioma',
          })
        };
      case 'en':
      default:
        return {
          flag: <EnFlag />,
          text: intl.formatMessage({
            id: 'switch_language_en',
            defaultMessage: 'Switch language',
          })
        };
    }
  }, [intl, locale]);
  return (
    <Container data-locale={ locale } onClick={ onClick }>
      { currentLanguage.flag }
      <Text>
        { currentLanguage.text }
      </Text>
    </Container>
  );
}
