import { createAction } from '@reduxjs/toolkit';

export type ImagePosition = {
  x: number;
  y: number;
};

function onSelectImagePosition(imagePosition: ImagePosition) {
  return {
    payload: imagePosition,
  };
}

export const selectImagePosition = createAction('card/select-image-position', onSelectImagePosition);
