import { useRouter } from 'next/router';
import { useMemo } from 'react';

export default function useCookiePolicyIdAndLanguage(): [number, 'en'|'it'|'fr'|'es'] {
  const {locale} = useRouter();
  const [shortLocale] = locale ? locale.split('-') : ['en'];

  return useMemo(() => {
    switch (shortLocale) {
      case 'it':
        return [80764364, 'it'];
      case 'fr':
        return [24296910, 'fr'];
      case 'es':
      case 'ca':
        return [28656348, 'es'];
      case 'en':
      default:
        return [48629607, 'en'];
    }
  }, [shortLocale]);
}
