import { configureStore } from '@reduxjs/toolkit';
import { passionsSlice } from './slices/passions.slice';
import { styleSlice } from './slices/style.slice';
import { frameSlice } from './slices/frame.slice';

const store = configureStore({
  reducer: {
    frame: frameSlice.reducer,
    passions: passionsSlice.reducer,
    style: styleSlice.reducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: false,
  }),
});

export type RootState = ReturnType<typeof store.getState>;

export default store;
