import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useRouter } from 'next/router';
import styled from 'styled-components';
import { Language, LanguageType } from '../ui/language';

const Container = styled.div`
  position: relative;
`;

const LanguageMenu = styled.div`
  position: absolute;
  left: 0;
  top: 100%;
  transform: translateY(3px);

  > div {
    margin-bottom: 3px;

    &:last-child {
      margin-bottom: 0;
    }
  }
`;

export function LanguageSelector() {
  const router = useRouter();
  const {pathname, asPath, query} = router;
  const [isMenuOpen, setMenuOpen] = useState(false);
  const dropdown = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!isMenuOpen) return;
    function handleClick(e: any) {
      if (dropdown.current && !dropdown.current.contains(e.target)) {
        setMenuOpen(false);
      }
    }
    window.addEventListener("click", handleClick);
    return () => window.removeEventListener("click", handleClick);
  }, [isMenuOpen]);

  const onChangeLocale = useCallback((e: any) => {
    setMenuOpen(false);
    router.push({pathname, query}, asPath, {locale: e.target.dataset.locale});
  }, [asPath, pathname, query, router]);

  const onToggleMenu = useCallback(() => {
    setMenuOpen(!isMenuOpen);
  }, [isMenuOpen]);

  const filteredLanguages = useMemo(() => {
    if (router && router.locales) return router.locales.filter((locale) => locale !== router.locale);
  }, [router]);

  const otherLanguages = useMemo(() => filteredLanguages && filteredLanguages.map(locale => {
    return <Language key={ locale } locale={ locale as LanguageType['locale'] } onClick={ onChangeLocale } />;
  }), [filteredLanguages, onChangeLocale]);

  return (
    <Container ref={dropdown}>
      <Language locale={ router.locale as LanguageType['locale'] } onClick={ onToggleMenu } />
      { isMenuOpen && (
        <LanguageMenu>
          { otherLanguages }
        </LanguageMenu>
      ) }
    </Container>
  );
}
