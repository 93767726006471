import { createAction } from '@reduxjs/toolkit';
import { FrameState } from '../slices/frame.slice';

function onSetUserPhoto(
  photo: FrameState['userPhoto'],
) {
  return {
    payload: photo,
  };
}

export const setUserPhoto = createAction('frame/set-user-photo', onSetUserPhoto);
